import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const button = css`
  width: 30px;
  height: 30px;
  border-radius: 40px;
  position: relative;

  ${theming((theme) => ({
    background: theme.object.secondaryObject,
  }))}

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 10px;
    transition: 200ms;
    border-radius: 0 30px 30px 0;
  }

  &[data-is-dark-theme='true'] {
    &::after {
      background-color: rgba(0, 0, 0, 0.5);
      transform: scale(-1) translateX(10px);
    }
  }

  &[data-is-dark-theme='false'] {
    &::after {
      background-color: white;
      transform: scale(1) translateX(0);
    }
  }
`;

export const themeButtonCss = {
  button,
};
