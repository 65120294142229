import React, { FC } from 'react';
import { Cart } from 'pages/Cart';
import { Redirect, Route, Switch } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { OrderDetails } from 'pages/OrderDetails';

export const CartRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path={endPoints.CART}>
        <Cart />
      </Route>
      <Route exact path={endPoints.CART_ORDER}>
        <OrderDetails />
      </Route>
      <Redirect to={endPoints.CART} />
    </Switch>
  );
};
