import React, { FC, useEffect } from 'react';
import { Button } from 'shared/ui/atoms/Button';
import { Input } from 'shared/ui/atoms/Input';
import { formCss } from './style';
import { TOnChange, TOnSubmit } from 'shared/types/common';
import { useForm } from 'effector-forms';
import { loginModel } from 'pages/Login/model';
import { useUnit } from 'effector-react';

export const Form: FC = () => {
  const { fields, values, errorText, submit } = useForm(
    loginModel.form.stores.$form,
  );
  const [isPending] = useUnit([loginModel.login.pendings.$isPending]);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <form onSubmit={onSubmit}>
      <Input
        classInputWrapper={formCss.inputWrapper}
        classInput={formCss.input}
        labelKey="Merchant ID"
        errorKey={errorText('merchant_id')}
        inputProps={{
          name: 'merchant_id',
          placeholder: 'Ваш Merchant ID...',
          defaultValue: values.merchant_id,
          onChange,
        }}
      />
      <Button
        type="submit"
        styleType="primary"
        className={formCss.submit}
        isLoading={isPending}>
        Создать приложение
      </Button>
    </form>
  );
};
