import React, { FC, useEffect } from 'react';
import { sharedCss } from 'shared/css';
import { productsViewCss } from './style';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { Image } from 'shared/ui/atoms/Image';
import { useParams } from 'react-router-dom';
import { productViewModel } from './model';
import { useUnit } from 'effector-react';
import NoImage from 'shared/assets/images/noImage.jpg';
import { Text } from 'shared/ui/atoms/Text';

export const ProductsView: FC = () => {
  const params = useParams<{ id: string }>();
  const [product] = useUnit([productViewModel.product.stores.$data]);

  useEffect(() => {
    if (params.id) {
      productViewModel.product.events.getProduct(params.id);
    }

    return () => {
      productViewModel.product.events.reset();
    };
  }, [params?.id]);

  return (
    <div className={`${sharedCss.pageContainer} ${productsViewCss.container}`}>
      <BoxHeader title="Карточка товара" />
      <div className={productsViewCss.content}>
        {product?.image ? (
          <Image
            className={productsViewCss.image}
            alt={product.name}
            src={
              typeof product?.image === 'string'
                ? product.image
                : URL.createObjectURL(product.image)
            }
          />
        ) : (
          <Image className={productsViewCss.image} alt="" src={NoImage} />
        )}
        <div className={productsViewCss.info}>
          <div className={productsViewCss.row}>
            <Text
              size="h4"
              weight="bold"
              className={productsViewCss.propertyName}>
              ID:
            </Text>
            <Text size="h4">{product?.id}</Text>
          </div>
          <div className={productsViewCss.row}>
            <Text
              size="h4"
              weight="bold"
              className={productsViewCss.propertyName}>
              Название:
            </Text>
            <Text size="h4">{product?.name}</Text>
          </div>
          <div className={productsViewCss.row}>
            <Text
              size="h4"
              weight="bold"
              className={productsViewCss.propertyName}>
              Цена:
            </Text>
            <Text size="h4">{product?.price}</Text>
          </div>
          <div className={productsViewCss.row}>
            <Text
              size="h4"
              weight="bold"
              className={productsViewCss.propertyName}>
              Описание:{' '}
            </Text>
            <Text size="h4">{product?.description}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};
