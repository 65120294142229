import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  min-width: 220px;
  max-width: 220px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 24px;
  position: relative;
  z-index: 1;

  ${theming((theme) => ({
    background: theme.leftSidebar.background,
    boxShadow: `0 0 15px ${theme.commonBoxShadow}`,
  }))}
`;

const menu = css``;

const section = css`
  margin-top: 15px;
`;

const sectionName = css`
  margin-bottom: 10px;
  text-transform: uppercase;
`;

const sectionMenuHeader = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-height: 38px;
  padding: 5px 0;

  &:hover p {
    ${theming((theme) => ({ color: theme.text.secondaryText }))}
  }

  & svg {
    transition: 300ms;
  }

  &[data-is-expanded='true'] {
    & svg {
      transform: rotate(180deg);
    }
  }
`;

const sectionMenuNameBox = css``;

const sectionMenuContent = css`
  border-left: 3px solid;
  padding: 10px 0 10px 10px;

  ${theming((theme) => ({
    borderLeftColor: theme.leftSidebar.menuContentLeftBorderColor,
  }))}
`;

const menuItem = css`
  padding: 8px 24px;
  border-radius: 4px;
  display: block;
  margin: 2.5px 0;
  width: 100%;
  text-align: left;

  &:hover p {
    ${theming((theme) => ({
      color: theme.text.primaryText,
    }))};
  }

  &[data-is-active='true'] {
    ${theming((theme) => ({
      background: theme.leftSidebar.menuItemBackground,
    }))}

    & p {
      font-weight: bold !important;
      ${theming((theme) => ({
        color: theme.text.primaryAccent,
      }))};
    }
  }
`;

export const leftSidebarCss = {
  container,
  menu,
  section,
  sectionName,
  sectionMenuHeader,
  sectionMenuNameBox,
  sectionMenuContent,
  menuItem,
};
