import { createForm } from 'effector-forms';
import { INotificationsFormFields, TUpdateChannelFx } from '../types';
import { createEffect, createEvent, sample } from 'effector';
import { servicesApi } from 'entities/Services/api';
import { servicesModel } from 'entities/Services/model';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';

const initForm = createEvent();

const $notificationsForm = createForm<INotificationsFormFields>({
  fields: {
    tg_channel_id: {
      init: '',
    },
  },
  validateOn: ['change', 'submit'],
});

const updateChannelFx = createEffect<TUpdateChannelFx>(
  servicesApi.updateServiceChannel,
);

const $isPending = updateChannelFx.pending;

sample({
  clock: initForm,
  source: servicesModel.selectedService.stores.$service,
  filter: (service) => Boolean(service?.tg_channel_id),
  fn: (service) => ({ tg_channel_id: service.tg_channel_id }),
  target: $notificationsForm.setInitialForm,
});

sample({
  clock: $notificationsForm.formValidated,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, fields) => ({
    shopId: service.id,
    params: {
      ...fields,
    },
  }),
  target: updateChannelFx,
});

sample({
  clock: updateChannelFx.doneData,
  fn: (res) => res.data,
  target: [
    servicesModel.selectedService.events.updateService,
    servicesModel.services.events.updateService,
  ],
});

sample({
  clock: updateChannelFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'Обновлено!',
    },
  }),
  target: showToastEvent,
});

export const form = {
  stores: {
    $notificationsForm,
  },
  events: {
    initForm,
  },
  pendings: {
    $isPending,
  },
};
