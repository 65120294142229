import { createEffect, createEvent, sample } from 'effector';
import { ICacheFactoryParams, ICacheFactoryResult } from './types';
import localForage from 'localforage';

export const cacheFactory = <T>({
  $store,
  key,
  defaultValue = null,
  isPureStore = false,
  instanceName,
}: ICacheFactoryParams<T>): ICacheFactoryResult<T> => {
  let storageInstance: LocalForage;

  if (instanceName) {
    storageInstance = localForage.createInstance({
      name: instanceName,
    });
  }

  const initCache = createEvent();
  const initCacheFx = createEffect<void, T>(async () => {
    if (instanceName) return await storageInstance.getItem(key);
    const data = await localForage.getItem(key);
    if (!data && defaultValue) return defaultValue;
    return data as T;
  });

  const cache = createEvent<T>();
  const cacheFx = createEffect<T, T>(async (value) => {
    if (instanceName) await storageInstance.setItem(key, value);
    else await localForage.setItem(key, value);
    return value;
  });

  sample({
    clock: cache,
    target: cacheFx,
  });

  sample({
    clock: initCache,
    target: initCacheFx,
  });

  sample({
    clock: $store,
    target: cacheFx,
  });

  sample({
    clock: initCacheFx.doneData,
    filter: (data) => Boolean(data || data === 0) && isPureStore,
    target: $store,
  });

  return {
    initCache,
    initCacheFx,
    cache,
    cacheFx,
  };
};
