import { useEffect } from 'react';
import { PAGE_SCROLL_TARGET_EL } from 'shared/config/constants';

export const useGlobalScrollDisabled = () => {
  useEffect(() => {
    const globalPageScrollEl = document.getElementById(PAGE_SCROLL_TARGET_EL);
    globalPageScrollEl.style.overflowY = 'hidden';

    return () => {
      globalPageScrollEl.style.overflowY = 'auto';
    };
  }, []);
};
