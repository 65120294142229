import { css } from '@linaria/core';

const container = css`
  display: flex;
  align-items: flex-start;
  height: 100%;
`;

const content = css`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const route = css`
  flex: 1;
  padding: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const mainRoutesCss = {
  container,
  content,
  route,
};
