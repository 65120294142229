import React, { FC } from 'react';
import { sharedCss } from 'shared/css';
import { testingCss } from './style';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { Text } from 'shared/ui/atoms/Text';
import { useUnit } from 'effector-react';
import { configModel } from 'entities/Config/model';
import { TEMPLATE_OPTIONS } from 'entities/Services/config';
import { Input } from 'shared/ui/atoms/Input';
import { Svg } from 'shared/ui/atoms/Svg';
import { showToast } from 'shared/lib/showToast';
import { EToastType } from 'shared/types/toast';

export const Testing: FC = () => {
  const config = useUnit(configModel.config.stores.$data);
  const template = TEMPLATE_OPTIONS.find(
    (template) => template.value === config?.service?.template,
  )?.label;

  const onCopyUrl = () => {
    showToast({
      toastParams: {
        type: EToastType.Success,
        messageKey: 'Ссылка на приложение скопирована в буфер',
      },
    });
    navigator.clipboard.writeText(config?.app_url ?? '');
  };

  return (
    <div className={sharedCss.pageContainer}>
      <BoxHeader title="Тестирование" className={testingCss.header} />

      <div className={`${sharedCss.boxContainer} ${testingCss.urlBox}`}>
        <Input
          labelKey="Ссылка для тестирования приложения"
          inputProps={{ value: config?.app_url, readOnly: true }}
          isWithError={false}
          classWrapper={testingCss.urlInputWrapper}
          classInput={testingCss.urlInput}
          rightContent={
            <button className={testingCss.copyUrlBtn} onClick={onCopyUrl}>
              <Svg idIcon="icCopy" width={22} height={22} />
            </button>
          }
        />
      </div>

      <div className={testingCss.row}>
        <div className={`${sharedCss.boxContainer} ${testingCss.container}`}>
          <BoxHeader
            title="Информация о Мерчанте"
            className={testingCss.merhantHeader}
            titleClassName={testingCss.merhantHeaderTitle}
            hideDivider
          />
          <div className={testingCss.content}>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                ID: <b>{config?.merchant?.id}</b>
              </Text>
            </div>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                Имя: <b>{config?.merchant?.name}</b>
              </Text>
            </div>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                Merchant ID: <b>{config?.merchant?.merchant_id}</b>
              </Text>
            </div>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                Merchant User ID: <b>{config?.merchant?.merchant_user_id}</b>
              </Text>
            </div>
          </div>
        </div>

        <div className={`${sharedCss.boxContainer} ${testingCss.container}`}>
          <BoxHeader
            title="Информация о приложении"
            className={testingCss.serviceHeader}
            titleClassName={testingCss.serviceHeaderTitle}
            hideDivider
          />
          <div className={testingCss.content}>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                ID: <b>{config?.service?.id}</b>
              </Text>
            </div>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                Название: <b>{config?.service?.name}</b>
              </Text>
            </div>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                Merchant ID: <b>{config?.service?.merchant_id}</b>
              </Text>
            </div>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                Service ID: <b>{config?.service?.service_id}</b>
              </Text>
            </div>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                Secret key: <b>{config?.service?.secret_key}</b>
              </Text>
            </div>
            <div className={testingCss.rowKeyValue}>
              <Text size="h4">
                Шаблон: <b>{template}</b>
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
