import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { OrderDetails } from 'pages/OrderDetails';
import { Orders } from 'pages/Orders';

export const OrdersRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path={endPoints.ORDERS}>
        <Orders />
      </Route>
      <Route exact path={endPoints.ORDER}>
        <OrderDetails />
      </Route>
      <Redirect to={endPoints.ORDERS} />
    </Switch>
  );
};
