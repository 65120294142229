import { EOrderStatus } from 'shared/types/api/orders';
import { ISelectOption } from 'shared/ui/atoms/Select/types';

export const STATUS_OPTIONS: ISelectOption<EOrderStatus>[] = [
  {
    label: `orders.status.${EOrderStatus.ALL}`,
    value: null,
  },
  {
    label: `orders.status.${EOrderStatus.PAID}`,
    value: EOrderStatus.PAID,
  },
  {
    label: `orders.status.${EOrderStatus.READY}`,
    value: EOrderStatus.READY,
  },
  {
    label: `orders.status.${EOrderStatus.DONE}`,
    value: EOrderStatus.DONE,
  },
  {
    label: `orders.status.${EOrderStatus.CANCELED}`,
    value: EOrderStatus.CANCELED,
  },
];
