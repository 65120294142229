import { TStrOrInt } from 'shared/types/common';

export interface ISvgProps {
  idIcon: string;
  width?: TStrOrInt;
  height?: TStrOrInt;
  className?: string;
  spriteName?: ESpriteNames;
  onClick?: () => void;
  id?: string;
}

export enum ESpriteNames {
  DEFAULT = 'default',
  TOAST = 'toast',
  COMPONENT = 'component',
}
