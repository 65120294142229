import React, { FC } from 'react';
import { IFileUploadProps } from './types';
import { useDropzone } from 'react-dropzone';
import { TOnDrop } from 'shared/types/common';
import { DragZone } from 'shared/ui/molecules/DragZone';
import { fileUploadCss } from './style';
import { Text } from 'shared/ui/atoms/Text';

export const FileUpload: FC<IFileUploadProps> = ({ field }) => {
  const onDrop: TOnDrop = (files) => {
    field.onChange(files[0]);
  };

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()} className={fileUploadCss.container}>
      <DragZone isDragActive={isDragActive} hasError={field.hasError()} />
      {field.value && (
        <Text
          size="h4"
          weight="bold"
          className={fileUploadCss.uploadedFile}>{`Загружен файл - ${
          (field.value as File).name
        }`}</Text>
      )}
    </div>
  );
};
