import { css } from '@linaria/core';

const form = css`
  margin-top: 15px;
`;

const button = css`
  margin-top: 15px;
`;

export const formCss = {
  form,
  button,
};
