import { createEffect, createEvent, sample } from 'effector';
import localforage from 'localforage';
import { TOKEN } from '../config';
import { token } from './token';
import { profileModel } from 'entities/Profile/model';
import { servicesModel } from 'entities/Services/model';
import { productsModel } from 'entities/Products/model';
import { configModel } from 'entities/Config/model';
import { ordersModel } from 'entities/Orders/model';
import { statisticsModel } from 'entities/Statistics/model';

const logoutAccount = createEvent();
const logoutAccountFx = createEffect(async () => {
  await localforage.clear();
  localStorage.removeItem(TOKEN);
  return true;
});

sample({
  clock: logoutAccount,
  target: logoutAccountFx,
});

sample({
  clock: logoutAccountFx.doneData,
  target: [
    token.events.resetToken,
    profileModel.profile.events.resetData,
    servicesModel.selectedService.events.resetService,
    productsModel.products.events.resetAll,
    servicesModel.services.events.resetAll,
    configModel.config.events.resetAll,
    ordersModel.cart.events.reset,
    ordersModel.list.events.reset,
    statisticsModel.stats.events.reset,
  ],
});

export const logout = {
  events: {
    logoutAccount,
  },
};
