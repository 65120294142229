import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IInput } from './types';
import { inputCss } from './style';
import { sharedCss } from 'shared/css';
import { TOnChange } from 'shared/types/common';
import { SpinnerBlock } from '../SpinnerBlock';
import { Svg } from '../Svg';

let timeout: NodeJS.Timeout;

export const Input: FC<IInput> = ({
  labelKey,
  errorKey,
  classInput = '',
  classLabel = '',
  classWrapper = '',
  classInputWrapper = '',
  classLeftContentWrapper = '',
  classRightContentWrapper = '',
  leftContent = null,
  rightContent = null,
  inputProps = {},
  errorRowCount = 1,
  isWithError = true,
  inputRef,
  testId,
  hasClear,
  debouncedOnChange,
}) => {
  const ref = useRef<HTMLInputElement>();
  const { t } = useTranslation();
  const [isTyping, setIsTyping] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const newInputProps = { ...inputProps };
  delete newInputProps.onChange;

  const onChange: TOnChange<HTMLInputElement> = (event) => {
    clearTimeout(timeout);

    if (debouncedOnChange) {
      setIsTyping(true);

      timeout = setTimeout(() => {
        debouncedOnChange.onChangeValue({
          value: event.target.value,
          name: inputProps?.name ?? event.target.name,
          event,
        });
        setIsTyping(false);
      }, Math.abs(debouncedOnChange.debounceTime));
      return;
    }

    inputProps?.onChange(event);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsTyping(false);
    setIsFocused(false);
  };

  const onClear = () => {
    ref.current.value = null;
    setIsTyping(false);

    debouncedOnChange &&
      debouncedOnChange.onChangeValue({
        value: '',
        ...(inputProps.name && { name: inputProps.name }),
      });

    if (!debouncedOnChange) {
      ref.current.value = '';
    }
  };

  return (
    <div className={classWrapper}>
      {labelKey && (
        <label
          className={`${inputCss.label} ${classLabel}`}
          htmlFor={inputProps?.name}>
          {t(labelKey)}
        </label>
      )}
      <div
        className={`${inputCss.inputWrapper} ${classInputWrapper}`}
        data-is-focused={isFocused}
        data-is-error={Boolean(errorKey)}>
        {leftContent && (
          <div
            className={`${inputCss.leftContentWrapper} ${classLeftContentWrapper}`}>
            {leftContent}
          </div>
        )}
        <input
          className={`${inputCss.input} ${classInput}`}
          onChange={onChange}
          {...newInputProps}
          ref={(value) => {
            ref.current = value;
            if (inputRef) inputRef.current = value;
          }}
          placeholder={t(inputProps.placeholder)}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {Boolean(rightContent || debouncedOnChange || hasClear) && (
          <div
            className={`${inputCss.rightContentWrapper} ${classRightContentWrapper}`}>
            {rightContent}
            {isTyping && <SpinnerBlock size={20} />}
            {Boolean(hasClear && ref?.current?.value) && (
              <button className={inputCss.clearButton} onClick={onClear}>
                <Svg idIcon="icCross" width={20} height={20} />
              </button>
            )}
          </div>
        )}
      </div>
      {isWithError && (
        <p
          className={sharedCss.errorClass}
          data-error-row-count={errorRowCount}>
          {errorKey && t(errorKey)}
        </p>
      )}
    </div>
  );
};
