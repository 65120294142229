import React, { FC, Suspense } from 'react';
import { mainRoutesCss } from './style';
import { LeftSidebar } from 'widgets/LeftSidebar';
import { Header } from 'widgets/Header';
import { Redirect, Route, Switch } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { PAGE_SCROLL_TARGET_EL } from 'shared/config/constants';
import { useInitRequests } from './lib/useInitRequests';
import { Import } from 'pages/Import';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { ProductsRoutes } from '../ProductsRoutes';
import { Testing } from 'pages/Testing';
import { CartRoutes } from '../CartRoutes';
import { OrdersRoutes } from '../OrdersRoutes';
import { Settings } from 'pages/Settings';
import { Notifications } from 'pages/Notifications';
import { Home } from 'pages/Home';

export const MainRoutes: FC = () => {
  useInitRequests();

  return (
    <div className={mainRoutesCss.container}>
      <LeftSidebar />
      <div className={mainRoutesCss.content}>
        <Header />
        <div className={mainRoutesCss.route} id={PAGE_SCROLL_TARGET_EL}>
          <Switch>
            <Route exact path={endPoints.HOME}>
              <Suspense fallback={<SpinnerBlock size={40} />}>
                <Home />
              </Suspense>
            </Route>
            <Route
              exact
              path={[
                endPoints.PRODUCTS,
                endPoints.PRODUCT_CREATE,
                endPoints.PRODUCT_EDIT,
                endPoints.PRODUCT_VIEW,
              ]}>
              <Suspense fallback={<SpinnerBlock size={40} />}>
                <ProductsRoutes />
              </Suspense>
            </Route>
            <Route exact path={[endPoints.CSV]}>
              <Suspense fallback={<SpinnerBlock size={40} />}>
                <Import />
              </Suspense>
            </Route>
            <Route exact path={[endPoints.ORDERS, endPoints.ORDER]}>
              <Suspense fallback={<SpinnerBlock size={40} />}>
                <OrdersRoutes />
              </Suspense>
            </Route>
            <Route exact path={[endPoints.CART, endPoints.CART_ORDER]}>
              <Suspense fallback={<SpinnerBlock size={40} />}>
                <CartRoutes />
              </Suspense>
            </Route>
            <Route exact path={[endPoints.TEST]}>
              <Suspense fallback={null}>
                <Testing />
              </Suspense>
            </Route>
            <Route exact path={[endPoints.NOTIFICATIONS]}>
              <Suspense fallback={null}>
                <Notifications />
              </Suspense>
            </Route>
            <Route exact path={[endPoints.SETTINGS]}>
              <Suspense fallback={null}>
                <Settings />
              </Suspense>
            </Route>
            <Redirect to={endPoints.HOME} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
