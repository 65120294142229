export const HEX_COLOR = /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
export const EMAIL =
  /^\s{0,}(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s{0,}$/;
export const ONLY_LETTERS = /^[a-zA-Z ]+$/;
export const ONLY_NUMBERS = /^[0-9 ]+$/;
export const ONLY_NUMBERS_LETTERS_SPACES =
  /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
export const PHONE = /^\s{0,}[0-9]{9,9}\s{0,}$/;
export const IS_URL =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const IS_TG_CHANNEL_NAME =
  /.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/;
