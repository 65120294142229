import React, { FC } from 'react';
import { ITableFilterProps } from './types';
import { Select } from 'shared/ui/atoms/Select';
import { Input } from 'shared/ui/atoms/Input';
import { tableFilterCss } from './style';
import { useTranslation } from 'react-i18next';

export const TableFilter: FC<ITableFilterProps> = ({
  name,
  data,
  onChangeSelect,
  onChangeInput,
}) => {
  const { t } = useTranslation();

  if (data.type === 'select') {
    return (
      <Select
        options={data.options}
        value={data.value}
        height="40"
        isWithError={false}
        isWithI18n
        onChange={onChangeSelect}
        customFilterOption={(option, value) =>
          t(option.data.label).toLowerCase().includes(value)
        }
      />
    );
  }

  if (data.type === 'input') {
    return (
      <Input
        classInput={tableFilterCss.input}
        classInputWrapper={tableFilterCss.inputWrapper}
        isWithError={false}
        hasClear
        inputProps={{
          name,
          defaultValue: data?.value ?? null,
        }}
        debouncedOnChange={{
          debounceTime: 750,
          onChangeValue: onChangeInput,
        }}
      />
    );
  }

  return null;
};
