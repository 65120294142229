import React, { FC } from 'react';
import { loginCss } from './style';
import { sharedCss } from 'shared/css';
import { Welcome } from './ui/Welcome';
import { Form } from './ui/Form';

export const Login: FC = () => {
  return (
    <div className={`${sharedCss.pageContainer} ${loginCss.container}`}>
      <div className={loginCss.content}>
        <Welcome />
        <Form />
      </div>
    </div>
  );
};
