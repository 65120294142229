import React, { FC, Fragment } from 'react';
import { useUnit } from 'effector-react';
import { orderDetailsModel } from 'pages/OrderDetails/model';
import { OrderInfo } from '../OrderInfo';
import { Text } from 'shared/ui/atoms/Text';
import { contentCss } from './style';
import { Products } from '../Products';

export const Content: FC = () => {
  const order = useUnit(orderDetailsModel.order.stores.$data);
  const hasData = Boolean(order?.id);
  return (
    <Fragment>
      {hasData ? (
        <Fragment>
          <Products data={order?.products} />
          <OrderInfo data={order} />
        </Fragment>
      ) : (
        <Text color="secondary" className={contentCss.notFound}>
          Информация о заказе не найдена
        </Text>
      )}
    </Fragment>
  );
};
