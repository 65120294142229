import React, { FC } from 'react';
import { ISericeButtonProps } from './types';
import { shopButtonCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { useIsSelectedService } from 'pages/Shops/lib/useIsSelectedService';
import { servicesModel } from 'entities/Services/model';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';

export const ServiceButton: FC<ISericeButtonProps> = ({ data }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isSelected = useIsSelectedService(data.id);

  const onClick = () => {
    servicesModel.selectedService.events.setService(data);
    history.push(endPoints.HOME);
  };

  return (
    <button
      data-is-selected={isSelected}
      className={shopButtonCss.button}
      onClick={onClick}>
      <Text size="h4" weight="bold">
        {data.name}
      </Text>
      <Text color="secondary">{t(`services.template.${data.template}`)}</Text>
    </button>
  );
};
