import React, { FC } from 'react';
import { ordersModel } from 'entities/Orders/model';
import { sharedCss } from 'shared/css';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { useUnit } from 'effector-react';
import { Table } from 'shared/ui/molecules/Table';
import { IOrder } from 'shared/types/api/orders';
import { ITableDataItem } from 'shared/ui/molecules/Table/types';
import { useHistory } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { filters } from './model/filters';
import { useForm } from 'effector-forms';
import { STATUS_OPTIONS } from 'entities/Orders/config';

export const Orders: FC = () => {
  const history = useHistory();
  const { values, fields } = useForm(filters.stores.filtersForm);
  const [orders, filteredOrders] = useUnit([
    ordersModel.list.stores.$orders,
    filters.stores.$orders,
  ]);
  const data: ITableDataItem<IOrder> = {
    headRows: [
      { name: 'ID', key: 'id' },
      { name: 'User ID', key: 'user_id' },
      { name: 'Кол-во товаров', key: 'products_amount' },
      { name: 'Сумма', key: 'total_sum' },
      {
        name: 'Статус',
        key: 'status',
        filter: {
          type: 'select',
          options: STATUS_OPTIONS,
          value: values.status,
        },
      },
      { name: 'Действия', key: 'actions' },
    ],
    bodyRows: filteredOrders ?? orders,
  };

  const onView = (id: string) => {
    history.push(endPoints.ORDER.replace(':id', id));
  };

  const onChangeSelect = (key: string) => (option) => {
    fields?.[key]?.onChange(option);
  };

  return (
    <div className={sharedCss.boxContainer}>
      <BoxHeader title="Заказы" />
      <Table<IOrder>
        data={data}
        hasFilters
        keys={[
          { name: 'id', hasCopy: true },
          { name: 'user_id' },
          'products_amount',
          'total_sum',
          { name: 'status', i18n: { path: 'orders.status' } },
        ]}
        mapKey="id"
        actions={{
          onView,
        }}
        onChangeSelect={onChangeSelect}
      />
    </div>
  );
};
