import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  ${theming((theme) => ({ backgroundColor: theme.object.primaryObject }))}
`;

const form = css`
  padding: 15px;
`;

const input = css`
  width: 100%;

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

const textarea = css`
  height: 200px;
`;

const submitButton = css`
  margin-top: 30px;
  width: 130px;
`;

export const productsCreateCss = {
  container,
  form,
  input,
  textarea,
  submitButton,
};
