import React, { FC } from 'react';
import { IDropdownItemProps } from './types';
import { Text } from 'shared/ui/atoms/Text';
import { dropdownItemCss } from './style';

export const DropdownItem: FC<IDropdownItemProps> = ({
  icon,
  title,
  onClick,
}) => {
  return (
    <button className={dropdownItemCss.button} onClick={onClick}>
      {icon && <div className={dropdownItemCss.iconBox}>{icon}</div>}
      <Text>{title}</Text>
    </button>
  );
};
