import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const wrapper = css`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }
  span {
    width: 100%;
    height: 100%;
  }
`;

const lazyLoadImg = css`
  width: auto;
  height: auto;
  border-radius: 0;
`;

const placeholder = css`
  width: auto;
  height: auto;
  border-radius: 0;

  animation: rotate 1s infinite;

  @keyframes rotate {
    0% {
      ${theming((theme) => ({
        backgroundColor: setAlpha(theme.object.secondaryObject, 0.7),
      }))}
    }
    50% {
      ${theming((theme) => ({
        backgroundColor: setAlpha(theme.object.secondaryObject, 0.5),
      }))}
    }
    100% {
      ${theming((theme) => ({
        backgroundColor: setAlpha(theme.object.secondaryObject, 0.7),
      }))}
    }
  }
`;

export const imageCss = {
  wrapper,
  placeholder,
  lazyLoadImg,
};
