import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  ${theming((theme) => ({ backgroundColor: theme.object.primaryObject }))}
`;

const content = css`
  display: flex;
  align-items: flex-start;
  padding: 15px;
`;

const row = css`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`;

const image = css`
  width: 300px;
  height: 300px;
  border-radius: 14px;

  img {
    object-fit: cover;
  }
`;

const info = css`
  flex: 1;
  padding-left: 15px;
`;

const propertyName = css`
  margin-right: 5px;
`;

export const productsViewCss = {
  container,
  content,
  row,
  image,
  info,
  propertyName,
};
