import React, { FC } from 'react';
import { Button } from 'shared/ui/atoms/Button';
import { Svg } from 'shared/ui/atoms/Svg';
import { createButtonCss } from './style';
import { createApplicationModalModel } from 'features/CreateApplicationModal/model';

export const CreateButton: FC = () => {
  const onClickCreate = () => {
    createApplicationModalModel.modal.setVisible(true);
  };

  return (
    <Button className={createButtonCss.button} onClick={onClickCreate}>
      <Svg idIcon="icPlus" width={24} height={24} />
      Создать новое приложение
    </Button>
  );
};
