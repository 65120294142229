import { css } from '@linaria/core';
import { DEFAULT_MOBILE_WIDTH } from 'shared/config/constants';
import { theming } from '.';

export const globalClass = css`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  :global() {
    @font-face {
      font-family: 'SFUIDisplay';
      font-weight: 700;
      src: url('shared/assets/fonts/SF/SF-Pro-Display-Bold.otf')
        format('opentype');
    }

    @font-face {
      font-family: 'SFUIDisplay';
      font-weight: 600;
      src: url('shared/assets/fonts/SF/SF-Pro-Display-Semibold.otf')
        format('opentype');
    }

    @font-face {
      font-family: 'SFUIDisplay';
      font-weight: 500;
      src: url('shared/assets/fonts/SF/SF-Pro-Display-Medium.otf')
        format('opentype');
    }

    @font-face {
      font-family: 'SFUIDisplay';
      font-weight: 400;
      src: url('shared/assets/fonts/SF/SF-Pro-Display-Regular.otf')
        format('opentype');
    }

    @font-face {
      font-family: 'SFUIDisplay';
      font-weight: 300;
      src: url('shared/assets/fonts/SF/SF-Pro-Display-Light.otf')
        format('opentype');
    }

    * {
      box-sizing: border-box;
    }

    html {
      scroll-behavior: smooth;
    }

    html,
    body {
      height: 100%;
    }

    body {
      margin: 0;
      font-family: 'SFUIDisplay', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow: hidden;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    #root {
      min-width: 100vw;
      height: 100vh;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    div {
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      margin: 0;
    }

    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      -webkit-transition: 300ms;
      -o-transition: 300ms;
      transition: 300ms;
      padding: 0;
      -webkit-tap-highlight-color: transparent;
    }

    a {
      cursor: pointer;
      text-decoration: none;
    }

    input,
    textarea {
      outline: none;
      background-color: transparent;
      border: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button,
    textarea::-webkit-outer-spin-button,
    textarea::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type='number'] {
      -moz-appearance: textfield; /* Firefox */
    }

    ~ .ReactModalPortal {
      * {
        box-sizing: border-box;
      }
    }

    .ReactModal__Content {
      outline: none;
    }

    .ReactModal__Body--open {
      overflow: hidden;
    }
  }
`;

export const toastContainerClass = css`
  z-index: 10000 !important;

  @media (max-width: ${DEFAULT_MOBILE_WIDTH}) {
    width: fit-content !important;
    bottom: 15px !important;
    margin: auto !important;
  }
`;

export const toastClass = css`
  -webkit-backdrop-filter: blur(17.5px);
  backdrop-filter: blur(6px);
  ${theming((theme) => ({
    backgroundColor: theme.object.primaryObject,
    boxShadow: `0 5px 15px ${theme.commonBoxShadow}`,
  }))}

  &.Toastify__toast {
    padding: 8px;
  }
`;
