import { css } from '@linaria/core';

const container = css`
  position: relative;
`;

const button = css`
  text-align: right;
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

const dropdown = css`
  padding: 0 !important;
`;

export const profileCss = { container, button, dropdown };
