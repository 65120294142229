import { createForm } from 'effector-forms';
import { rules } from 'shared/lib/rules';
import { ICreateFormFields, TCreateServiceFx } from '../types';
import { createEffect, sample } from 'effector';
import { servicesApi } from 'entities/Services/api';
import { ICreateServiceParams } from 'shared/types/api/shops';
import { servicesModel } from 'entities/Services/model';
import { modal } from './modal';
import { TEMPLATE_OPTIONS } from 'entities/Services/config';
import { endPoints } from 'shared/config/endPoints';
import { redirect } from 'shared/lib/redirect';

const $createForm = createForm<ICreateFormFields>({
  fields: {
    service_id: {
      init: '',
      rules: [rules.required()],
    },
    name: {
      init: '',
      rules: [rules.required()],
    },
    template: {
      init: TEMPLATE_OPTIONS[0],
      rules: [rules.required()],
    },
  },
  validateOn: ['change', 'submit'],
});

const createServiceFx = createEffect<TCreateServiceFx>(
  servicesApi.createService,
);

const $isCreateServicePending = createServiceFx.pending;

sample({
  clock: $createForm.formValidated,
  fn: (fields): ICreateServiceParams => ({
    ...fields,
    template: fields.template.value,
    secret_key: `secret_key_${fields.service_id}`,
  }),
  target: createServiceFx,
});

sample({
  clock: createServiceFx.doneData,
  fn: (res) => res.data,
  target: servicesModel.selectedService.events.setService,
});

sample({
  clock: createServiceFx.doneData,
  fn: () => endPoints.CSV,
  target: redirect,
});

sample({
  clock: createServiceFx.doneData,
  fn: (res) => res.data,
  target: servicesModel.services.events.pushService,
});

sample({
  clock: createServiceFx.doneData,
  source: servicesModel.services.stores.$list,
  target: modal.resetModal,
});

sample({
  clock: modal.$store,
  filter: (isOpen) => !isOpen,
  target: $createForm.reset,
});

export const form = {
  stores: {
    $createForm,
  },
  pendings: {
    $isCreateServicePending,
  },
};
