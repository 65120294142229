import { useUnit } from 'effector-react';
import React, { Suspense, useEffect, useState } from 'react';
import { ILazyComponent } from './types';

export const LazyComponent: React.FC<ILazyComponent> = ({
  $store,
  component: Component,
  withAnimation,
}) => {
  const store = useUnit($store);
  const [visible, setVisible] = useState(false);
  const isOpen =
    store == null
      ? Boolean(store)
      : typeof store === 'object' && Object.hasOwn(store, 'isOpen')
      ? Boolean(store?.isOpen)
      : Boolean(store);

  useEffect(() => {
    if (!isOpen)
      setTimeout(() => {
        setVisible(isOpen);
      }, 250);
    else setVisible(isOpen);
  }, [isOpen]);

  if (withAnimation ? !visible : !isOpen) return null;

  return (
    <Suspense fallback={<React.Fragment />}>
      <Component />
    </Suspense>
  );
};
