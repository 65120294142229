import { createEffect, createEvent, createStore, sample } from 'effector';
import { createForm } from 'effector-forms';
import { IOrder } from 'shared/types/api/orders';
import { ordersApi } from 'entities/Orders/api';
import { TGetOrdersFx } from 'entities/Orders/types';
import { servicesModel } from 'entities/Services/model';
import { IFiltersFormField } from '../types';
import { STATUS_OPTIONS } from 'entities/Orders/config';

export const filtersForm = createForm<IFiltersFormField>({
  fields: {
    status: {
      init: STATUS_OPTIONS[0],
    },
  },
  validateOn: ['change'],
});

const resetOrders = createEvent();
const $orders = createStore<IOrder[]>(null).reset(resetOrders);

const getOrdersFx = createEffect<TGetOrdersFx>(ordersApi.getOrders);

sample({
  clock: filtersForm.$values,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, values) => ({
    shopId: service.id,
    filters: {
      status: values.status.value,
    },
  }),
  target: getOrdersFx,
});

sample({
  clock: getOrdersFx.doneData,
  fn: (res) => res.data,
  target: $orders,
});

export const filters = {
  stores: {
    filtersForm,
    $orders,
  },
  events: {
    resetOrders,
  },
};
