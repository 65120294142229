import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITextareaProps } from './types';
import { inputCss } from './style';
import { sharedCss } from 'shared/css';
import { TOnChange } from 'shared/types/common';

export const Textarea: FC<ITextareaProps> = ({
  labelKey,
  errorKey,
  classInput = '',
  classLabel = '',
  classWrapper = '',
  inputProps = {},
  errorRowCount = 1,
  isWithError = true,
}) => {
  const ref = useRef<HTMLTextAreaElement>();
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const newInputProps = { ...inputProps };
  delete newInputProps.onChange;

  const onChange: TOnChange<HTMLTextAreaElement> = (event) => {
    inputProps?.onChange(event);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={classWrapper}>
      {labelKey && (
        <label
          className={`${inputCss.label} ${classLabel}`}
          htmlFor={inputProps?.name}>
          {t(labelKey)}
        </label>
      )}
      <textarea
        className={`${inputCss.input} ${classInput}`}
        data-is-focused={isFocused}
        data-is-error={Boolean(errorKey)}
        onChange={onChange}
        {...newInputProps}
        ref={ref}
        placeholder={t(inputProps.placeholder)}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {isWithError && (
        <p
          className={sharedCss.errorClass}
          data-error-row-count={errorRowCount}>
          {errorKey && t(errorKey)}
        </p>
      )}
    </div>
  );
};
