import React, { FC } from 'react';
import { orderInfoCss } from './style';
import { IOrderInfoProps } from './types';
import { Text } from 'shared/ui/atoms/Text';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui/atoms/Button';
import { getActionName } from 'pages/OrderDetails/lib/getActionName';
import { Svg } from 'shared/ui/atoms/Svg';
import { orderDetailsModel } from 'pages/OrderDetails/model';
import { useUnit } from 'effector-react';
import { EOrderStatus } from 'shared/types/api/orders';
import { confirmModalModel } from 'features/ConfirmModal/model';

export const OrderInfo: FC<IOrderInfoProps> = ({ data }) => {
  const { t } = useTranslation();
  const [isPending, isCanceling] = useUnit([
    orderDetailsModel.actions.pendings.$isPending,
    orderDetailsModel.actions.stores.$isCanceling,
  ]);
  const actionInfo = getActionName(data.status);

  const onClickSetNextStatus = () => {
    orderDetailsModel.actions.events.updateStatus(actionInfo?.nextStatus);
  };

  const onClickCancel = () => {
    confirmModalModel.modal.setVisible({
      isOpen: true,
      params: {
        title: 'orderDetails.deleteOrderTitle',
        text: 'orderDetails.deleteOrderText',
        onConfirm: () => {
          confirmModalModel.modal.resetModal();
          orderDetailsModel.actions.events.updateStatus(EOrderStatus.CANCELED);
        },
      },
    });
  };

  return (
    <div className={orderInfoCss.container}>
      <div>
        <div className={orderInfoCss.statusBox}>
          <Text size="h3">Статус:</Text>
          <span className={orderInfoCss.status} data-is-status={data.status}>
            {t(`orders.status.${data.status}`)}
          </span>
        </div>
        <Text size="h3">
          Общее кол-во товаров: <b>{data.products_amount}</b>
        </Text>
      </div>
      <div className={orderInfoCss.totalSum}>
        <div>
          <Text size="h3">Общая стоимость:</Text>
          <Text size="h2" weight="bold">
            {data.total_sum}
          </Text>
        </div>
        {actionInfo && (
          <div className={orderInfoCss.buttons}>
            <Button
              onClick={onClickSetNextStatus}
              isLoading={isPending && !isCanceling}
              isDisabled={isPending}>
              {t(actionInfo.name)}
            </Button>
            <Button
              styleType="tertiary"
              className={orderInfoCss.cancelBtn}
              isLoading={isPending && isCanceling}
              isDisabled={isPending}
              onClick={onClickCancel}>
              <Svg idIcon="icCross" width={40} height={40} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
