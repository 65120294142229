import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const filters = css`
  padding: 15px;
`;

const limit = css`
  display: flex;
  align-items: center;

  & p {
    margin-left: 10px;
  }
`;

const selectLimitWrapper = css`
  width: 122px;
`;

const selectLimit = css`
  min-height: auto !important;
  height: 35px;
`;

const searchWrapper = css`
  margin-left: auto;
`;

const searchInput = css`
  height: 40px !important;
`;

const tableContainer = css`
  table {
    border-collapse: collapse;
    table-layout: fixed;
  }

  thead {
    th {
      padding: 10px;
      word-wrap: break-word;
      white-space: break-spaces;
    }
  }

  tbody td:last-child {
    border-right: none;
  }

  tbody {
    td {
      border-bottom: 1px solid;
      border-top: 1px solid;
      border-right: 1px solid;
      padding: 10px;
      overflow: hidden;
      word-wrap: break-word;
      white-space: break-spaces;
      vertical-align: top;

      ${theming((theme) => ({
        borderColor: theme.stroke.primary,
      }))}
    }
  }
`;

const pagination = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-right: -15px;
`;

const paginationButtons = css`
  display: flex;
  align-items: center;
`;

const paginationButton = css`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    transform: rotate(90deg);
  }

  &:last-child {
    transform: rotate(-90deg);
  }

  &[disabled] {
    opacity: 0.5;
  }

  &:not([disabled]):hover svg {
    ${theming((theme) => ({
      color: theme.icon.accent,
    }))}
  }
`;

const actionsTd = css`
  width: 1%;

  & button {
    display: inline;
    margin: 2.5px
    height: 40px;
  }
`;

const sortIcon = css`
  margin-left: 10px;
  transition: 200ms;
`;

const emptyList = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  border-top: 1px solid;

  ${theming((theme) => ({
    backgroundColor: theme.table.trOddBackground,
    borderTopColor: theme.stroke.primary,
  }))}
`;

const head = css`
  th {
    border-bottom: 1px solid;
    border-top: 1px solid;
    border-right: 1px solid;
    text-align: left;

    ${theming((theme) => ({
      borderColor: theme.stroke.primary,
    }))}

    &[data-is-sort-enabled='true'] {
      cursor: pointer;

      &:hover {
        ${theming((theme) => ({
          background: setAlpha(
            theme.button.filled.primary.background.default,
            0.1,
          ),
        }))}
      }
    }

    &[data-is-sort-active='true'] {
      .${sortIcon} {
        transform: rotate(180deg) scale(1.2);
        ${theming((theme) => ({
          color: theme.icon.accent,
        }))}
      }
    }

    & div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &[data-has-filters='false'] th {
    border-top: none;
  }


`;

const filtersRow = css`
  th {
    border-right: 1px solid;
    ${theming((theme) => ({
      borderColor: `${theme.stroke.primary}`,
    }))}
  }

  &[data-is-actions-visible='false'] th:last-child {
    border-right: none;
  }
`;

const copyButton = css`
  margin-left: auto;
  display: block;

  & svg {
    ${theming((theme) => ({
      color: theme.icon.secondary,
    }))}
  }

  &:hover svg {
    ${theming((theme) => ({
      color: theme.icon.primary,
    }))}
  }
`;

export const tableCss = {
  filters,
  limit,
  tableContainer,
  selectLimitWrapper,
  selectLimit,
  searchWrapper,
  searchInput,
  pagination,
  paginationButtons,
  paginationButton,
  actionsTd,
  sortIcon,
  emptyList,
  head,
  filtersRow,
  copyButton,
};
