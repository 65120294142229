import { css } from '@linaria/core';

const content = css`
  padding: 20px;
`;

const text = css`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const notificationsCss = {
  content,
  text,
};
