import { ISelectOption } from 'shared/ui/atoms/Select/types';

export const SELECTED_SERVICE_ID_CACHE_KEY = '@selectedServiceId';

export const TEMPLATE_OPTIONS: ISelectOption<string>[] = [
  { label: 'Ресторан', value: 'restaurant' },
  {
    label: 'Магазин',
    value: 'shop',
  },
  { label: 'Услуга', value: 'service' },
];
