import { setAlpha } from './lib/setAlpha';
import { DefaultTheme } from './types';

export const darkTheme: DefaultTheme = {
  type: 'dark',
  object: {
    primaryObject: '#202024',
    secondaryObject: '#2C2D35',
    primaryScreen: '#242429',
    secondaryScreen: '#1A1B24',
    primaryWhiteBg: '#FFFFFF',
    primaryTextfield: '#34353F',
    primaryHoverTextfield: '#282931',
    primaryErrorTextfield: '#3f3434',
  },
  switch: {
    active: {
      background: '#2C2D35',
      color: '#0073FF',
    },
    inactive: {
      background: '#2C2D35',
      color: '#202024',
    },
    boxShadow: 'rgba(255,255,255,0.2)',
  },
  button: {
    filled: {
      tertiary: {
        background: {
          default: '#202024',
          hover: '#26262a',
        },
      },
      primary: {
        background: {
          default: 'linear-gradient(0deg, #0073FF, #00C2FF)',
          hover: 'linear-gradient(-45deg, #0073FF, #00C2FF)',
        },
      },
      secondary: {
        background: {
          default: '#2d2d33',
          hover: '#33333a',
        },
      },
      warning: {
        background: {
          default: 'linear-gradient(0deg, #FF8786, #FD7172)',
          hover: 'linear-gradient(-45deg, #FF8786, #FD7172)',
        },
      },
      disabled: {
        background: {
          default: '#363744',
        },
      },
    },
    outlined: {
      tertiary: {
        borderColor: {
          default: '#202024',
          hover: '#202024',
        },
      },
      primary: {
        borderColor: {
          default: '#0073FF',
          hover: '#00C2FF',
        },
      },
      secondary: {
        borderColor: {
          default: '#b3b7ce',
          hover: '#878a9f',
        },
      },
      warning: {
        borderColor: {
          default: '#FF8786',
          hover: '#ffc3c2',
        },
      },
      disabled: {
        borderColor: {
          default: '#363744',
        },
      },
    },
  },
  text: {
    primaryAccent: '#0073FF',
    primaryHoverAccent: '#00C2FF',
    primaryText: '#FFFFFF',
    primaryButton: '#FFFFFF',
    secondaryText: '#B3B7CE',
    secondaryHoverText: '#878a9f',
    tertiaryText: '#9093A2',
    warningText: '#FF706F',
    warningHoverText: '#ffc3c2',
    waitText: '#FF706F',
    disabledButtonText: '#575965',
  },
  stroke: {
    primary: '#4B4B59',
    secondary: '#B7B8C6',
    accent: '#0073FF',
    error: '#FD7172',
  },
  icon: {
    primary: '#FFFFFF',
    accent: '#0073FF',
    secondary: '#9395A5',
    tertiary: '#646778',
    warning: '#FF706F',
    wait: '#F9C679',
  },
  system: {
    bgBehind: setAlpha('#1A1B25', 0.8),
    redToYellow: 'linear-gradient(0deg, #FF716F, #FCE217)',
    darkBlueToLightBlue: 'linear-gradient(0deg, #3A8AF6, #5FE4FF)',
  },
  leftSidebar: {
    background: '#202024',
    menuContentLeftBorderColor: '#36363a',
    menuItemBackground: '#1b1b1d',
  },
  header: {
    background: '#222226',
  },
  commonBoxShadow: 'rgba(255,255,255,0.02)',
  modalBoxShadow: 'rgba(255,255,255,0.06)',
  table: {
    trOddBackground: '#242429',
  },
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    blue: '#0073FF',
    red: '#FF706F',
    green: '#66CA28'
  },
  defaultSpinnerColor: 'rgba(170,170,170,0.17)',
};

export const lightTheme: DefaultTheme = {
  type: 'light',
  object: {
    primaryObject: '#FFFFFF',
    secondaryObject: '#C9CBD9',
    primaryScreen: '#E2E4F0',
    secondaryScreen: '#D5D7E5',
    primaryWhiteBg: '#FFFFFF',
    primaryTextfield: '#FFFFFF',
    primaryHoverTextfield: '#F6F8FF',
    primaryErrorTextfield: '#FFF5F5',
  },
  switch: {
    active: {
      background: '#C9CBD9',
      color: '#0073FF',
    },
    inactive: {
      background: '#C9CBD9',
      color: '#FFFFFF',
    },
    boxShadow: 'rgba(0,0,0,0.2)',
  },
  button: {
    filled: {
      tertiary: {
        background: {
          default: '#FFFFFF',
          hover: '#fcf7f7',
        },
      },
      primary: {
        background: {
          default: 'linear-gradient(0deg, #0073FF, #00C2FF)',
          hover: 'linear-gradient(-45deg, #0073FF, #00C2FF)',
        },
      },
      secondary: {
        background: {
          default: '#d7dae6',
          hover: '#cbcfdb',
        },
      },
      warning: {
        background: {
          default: 'linear-gradient(0deg, #FF8786, #FD7172)',
          hover: 'linear-gradient(-45deg, #FF8786, #FD7172)',
        },
      },
      disabled: {
        background: {
          default: '#CCCFDE',
        },
      },
    },
    outlined: {
      tertiary: {
        borderColor: {
          default: '#FFFFFF',
          hover: '#FFFFFF',
        },
      },
      primary: {
        borderColor: {
          default: '#0073FF',
          hover: '#00C2FF',
        },
      },
      secondary: {
        borderColor: {
          default: '#6a6a6a',
          hover: '#abacae',
        },
      },
      warning: {
        borderColor: {
          default: '#FF8786',
          hover: '#af2e2f',
        },
      },
      disabled: {
        borderColor: {
          default: '#CCCFDE',
        },
      },
    },
  },
  text: {
    primaryAccent: '#0073FF',
    primaryHoverAccent: '#00C2FF',
    primaryText: '#363845',
    primaryButton: '#FFFFFF',
    secondaryText: '#6A6A6A',
    secondaryHoverText: '#7f7d7d',
    tertiaryText: '#9093A2',
    warningText: '#FD7172',
    warningHoverText: '#af2e2f',
    waitText: '#FCA523',
    disabledButtonText: '#91939E',
  },
  stroke: {
    primary: '#D8DBF0',
    secondary: '#B7B8C6',
    accent: '#0073FF',
    error: '#FD7172',
  },
  icon: {
    primary: '#535469',
    accent: '#0073FF',
    secondary: '#9395A5',
    tertiary: '#9395A5',
    warning: '#FD7172',
    wait: '#FCA523',
  },
  system: {
    bgBehind: '#FFFFFF',
    redToYellow: 'linear-gradient(0deg, #FF716F, #FCE217)',
    darkBlueToLightBlue: 'linear-gradient(0deg, #3A8AF6, #5FE4FF)',
  },
  leftSidebar: {
    background: '#FFFFFF',
    menuContentLeftBorderColor: '#E0F3FF',
    menuItemBackground: '#F6F8FF',
  },
  header: {
    background: '#F6F8FF',
  },
  commonBoxShadow: 'rgba(0,0,0,0.04)',
  modalBoxShadow: 'rgba(0,0,0,0.1)',
  table: {
    trOddBackground: '#f9f9f9',
  },
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    blue: '#0073FF',
    red: '#FF706F',
    green: '#66CA28'
  },
  defaultSpinnerColor: 'rgba(170,170,170,0.17)',
};

export const colors = {
  light: lightTheme,
  dark: darkTheme,
};

export const theming = (
  cb: (allCollor: typeof colors.light) => any,
): { [key: string]: string } =>
  Object.keys(colors).reduce(
    (acc, name) =>
      Object.assign(acc, {
        [`.theme-${name} &`]: cb(colors[name]),
      }),
    {},
  );

export const themingValue = (
  cb: (allCollor: typeof colors.light) => string,
): string => Object.keys(colors).reduce((acc, name) => cb(colors[name]), '');
