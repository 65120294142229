import { createEffect, createEvent, createStore, sample } from 'effector';
import { servicesApi } from '../api';
import { TGetServicesFx } from '../types';
import { IService } from 'shared/types/api/shops';

const resetAll = createEvent();

const $list = createStore<IService[]>(null).reset(resetAll);
const $servicesCount = createStore<number>(0).reset(resetAll);

const getServices = createEvent();
const getServicesFx = createEffect<TGetServicesFx>(servicesApi.getServices);

const $isPending = getServicesFx.pending;

sample({
  clock: getServices,
  target: getServicesFx,
});

sample({
  clock: getServicesFx.doneData,
  fn: (res) => res.data,
  target: $list,
});

sample({
  clock: $list,
  fn: (list) => list?.length ?? 0,
  target: $servicesCount,
});

const pushService = createEvent<IService>();

sample({
  clock: pushService,
  source: $list,
  filter: (_, newService) => Boolean(newService),
  fn: (list, newService) => [...list, newService],
  target: $list,
});

const updateService = createEvent<IService>();

sample({
  clock: updateService,
  source: $list,
  filter: (_, newService) => Boolean(newService),
  fn: (list, newService) =>
    list.map((service) =>
      service.id === newService.id ? newService : service,
    ),
  target: $list,
});

export const services = {
  stores: {
    $list,
    $servicesCount,
  },
  events: {
    getServices,
    pushService,
    updateService,
    resetAll,
  },
  pendings: {
    $isPending,
  },
};
