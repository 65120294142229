import { createEvent, createStore, sample } from 'effector';
import { PROFILE_CACHE_KEY } from 'entities/Auth/config';
import { IProfileData } from '../types';
import { cacheFactory } from 'shared/lib/cacheFactory';

const resetData = createEvent();
const setData = createEvent<IProfileData>();
const $data = createStore<IProfileData>(null).reset(resetData);

const { initCache } = cacheFactory({
  key: PROFILE_CACHE_KEY,
  $store: $data,
  isPureStore: true,
});

sample({
  clock: setData,
  target: $data,
});

export const profile = {
  stores: {
    $data,
  },
  events: {
    resetData,
    setData,
    initCache,
  },
};
