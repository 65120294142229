import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;

  ${theming((theme) => ({
    boxShadow: `0 -5px 15px ${theme.commonBoxShadow}`,
  }))}
`;

const statusBox = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const status = css`
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  padding: 2.5px 10px;
  margin-left: 5px;

  ${theming((theme) => ({
    backgroundColor: theme.colors.blue,
    color: theme.colors.white,
  }))}

  &[data-is-status='done'] {
    ${theming((theme) => ({
      backgroundColor: theme.colors.green,
    }))}
  }

  &[data-is-status='canceled'] {
    ${theming((theme) => ({
      backgroundColor: theme.colors.red,
    }))}
  }
`;

const totalSum = css`
  text-align: right;
  display: flex;
  align-items: center;
`;

const buttons = css`
  margin-left: 15px;
  display: flex;
  align-items: center;
`;

const cancelBtn = css`
  margin-left: 10px;
  padding: 0;
  width: 50px;

  svg {
    ${theming((theme) => ({ color: theme.icon.warning }))}
  }
`;

export const orderInfoCss = {
  container,
  statusBox,
  status,
  totalSum,
  buttons,
  cancelBtn,
};
