import React, { FC } from 'react';
import { IStatisticsProduct } from 'shared/types/api/statistics';
import { ITableDataItem } from 'shared/ui/molecules/Table/types';
import { ITopProductsProps } from './types';
import { Table } from 'shared/ui/molecules/Table';
import { sharedCss } from 'shared/css';
import { topProductsCss } from './style';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { endPoints } from 'shared/config/endPoints';
import { useHistory } from 'react-router-dom';

export const TopProducts: FC<ITopProductsProps> = ({ data = [] }) => {
  const history = useHistory();
  const tableData: ITableDataItem<IStatisticsProduct> = {
    headRows: [
      { name: 'ID', key: 'id' },
      { name: 'Название', key: 'name' },
      { name: 'Кол-во просмотров', key: 'views' },
      { name: 'Действия', key: 'actions' },
    ],
    bodyRows: data,
  };

  const onView = (id: string) => {
    history.push(endPoints.PRODUCT_VIEW.replace(':id', id));
  };

  return (
    <div className={`${sharedCss.boxContainer} ${topProductsCss.container}`}>
      <BoxHeader title="Топ 5 товаров" />
      <Table<IStatisticsProduct>
        data={tableData}
        keys={[
          { name: 'id', hasCopy: true },
          { name: 'name' },
          { name: 'views' },
        ]}
        mapKey="id"
        actions={{
          onView,
        }}
      />
    </div>
  );
};
