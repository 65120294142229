import React, { FC } from 'react';
import { dropdownCss } from './style';
import { IDropdown } from './types';
import { sharedCss } from 'shared/css';

export const Dropdown: FC<IDropdown> = ({
  isOpen,
  className = '',
  children,
  onMouseLeave,
}) => {
  return (
    <div
      className={`${dropdownCss.wrapper} ${className} ${
        isOpen ? sharedCss.animation.fadeIn : sharedCss.animation.fadeOut
      }`}
      data-is-open={isOpen}
      onMouseLeave={onMouseLeave}>
      {children}
    </div>
  );
};
