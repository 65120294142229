import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  display: flex;
  flex-direction: column;

  ${theming((theme) => ({
    background: theme.object.primaryObject,
  }))};
`;

const textareaWrapper = css`
  display: flex;
  flex-direction: column;
  height: 75%;
`;

const textarea = css`
  flex: 1;
  resize: vertical;
`;

const checkbox = css`
  margin-top: 30px;
`;

const content = css`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const button = css`
  margin-top: 30px;
`;

export const importCss = {
  container,
  textareaWrapper,
  textarea,
  checkbox,
  content,
  button,
};
