import React, { FC, useEffect } from 'react';
import { useForm } from 'effector-forms';
import { useUnit } from 'effector-react';
import { notificationsModel } from 'pages/Notifications/model';
import { TOnChange, TOnSubmit } from 'shared/types/common';
import { Input } from 'shared/ui/atoms/Input';
import { formCss } from './style';
import { Button } from 'shared/ui/atoms/Button';

export const Form: FC = () => {
  const [isPending] = useUnit([notificationsModel.form.pendings.$isPending]);
  const { fields, hasError, errorText, submit } = useForm(
    notificationsModel.form.stores.$notificationsForm,
  );

  useEffect(() => {
    notificationsModel.form.events.initForm();
  }, []);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <form className={formCss.form} onSubmit={onSubmit}>
      <Input
        isWithError={hasError('tg_channel_id')}
        errorKey={errorText('tg_channel_id')}
        inputProps={{
          name: 'tg_channel_id',
          placeholder: '@channelusername',
          value: fields.tg_channel_id.value,
          onChange,
        }}
      />
      <Button isLoading={isPending} className={formCss.button}>
        Сохранить
      </Button>
    </form>
  );
};
