import { TPrepareRequestParams } from './types';

export const prepareRequestParams: TPrepareRequestParams = (fields) => {
  const formData = new FormData();
  const isFile = fields.image instanceof File;
  formData.append('name', fields.name);
  formData.append('price', fields.price);
  formData.append('description', fields.description);

  if (fields.image)
    formData.append(isFile ? 'image' : 'image_url', fields.image);

  return formData;
};
