import { css } from '@linaria/core';

const input = css`
  height: 40px;
`;

const inputWrapper = css`
  width: 100%;
`;

export const tableFilterCss = {
  input,
  inputWrapper,
};
