import { useEffect } from 'react';
import { mobileModel } from 'shared/model/mobile';

export const useAdaptiveScreen = () => {
  useEffect(() => {
    const handleResize = () => {
      mobileModel.events.setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};
