import React, { FC, Fragment, useRef } from 'react';
import { productsCreateCss } from 'pages/ProductsCreate/style';
import { Input } from 'shared/ui/atoms/Input';
import { imageLoaderCss } from './style';
import { IImageLoaderProps } from './types';
import { isImageFile } from 'shared/lib/isImageFile';
import { showToast } from 'shared/lib/showToast';
import { useUnit } from 'effector-react';
import { productsCreateModel } from 'pages/ProductsCreate/model';
import { EToastType } from 'shared/types/toast';
import { TOnChange } from 'shared/types/common';
import { Button } from 'shared/ui/atoms/Button';
import { Text } from 'shared/ui/atoms/Text';
import { Image } from 'shared/ui/atoms/Image';

export const ImageLoader: FC<IImageLoaderProps> = ({ field }) => {
  const product = useUnit(productsCreateModel.form.stores.$product);
  const imageRef = useRef<HTMLInputElement>();
  const image = field.value || product?.image;

  const onChange: TOnChange = (e) => {
    field.onChange(e.target.value);
  };

  const onClickUpload = () => {
    const el = document.createElement('input');
    el.type = 'file';
    el.click();

    el.onchange = () => {
      const file = el.files[0];

      if (!isImageFile(file)) {
        showToast({
          toastParams: {
            type: EToastType.Error,
            messageKey: 'createProduct.errors.onlyImageAllowed',
          },
        });
        return;
      }

      field.onChange(file);
      imageRef.current.value = file.name;
    };

    el.oncancel = () => {
      el.remove();
    };
  };

  return (
    <Fragment>
      <Input
        labelKey="Изображение товара"
        classWrapper={productsCreateCss.input}
        classInput={imageLoaderCss.input}
        inputRef={imageRef}
        errorKey={field.errorText()}
        inputProps={{
          name: 'image',
          placeholder: 'Введите URL изображения или загрузите его',
          onChange,
        }}
        rightContent={
          <Button
            type="button"
            className={imageLoaderCss.uploadButton}
            onClick={onClickUpload}>
            Загрузить
          </Button>
        }
      />
      {image && (
        <div>
          <Text size="h4" weight="bold">
            Загруженное изображение
          </Text>
          <Image
            className={imageLoaderCss.uploadedImage}
            alt=""
            src={typeof image === 'string' ? image : URL.createObjectURL(image)}
          />
        </div>
      )}
    </Fragment>
  );
};
