import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const button = css`
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  position: relative;

  &:hover {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 5px;
      bottom: 5px;
      width: 2px;
      border-radius: 0 4px 4px 0;

      ${theming((theme) => ({
        background: theme.colors.blue,
      }))}
    }
  }

  &:hover p,
  &:hover svg {
    ${theming((theme) => ({
      color: theme.colors.blue,
    }))}
  }
`;

const iconBox = css`
  margin-right: 10px;
`;

export const dropdownItemCss = {
  button,
  iconBox,
};
