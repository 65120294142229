import { createForm } from 'effector-forms';
import { rules } from 'shared/lib/rules';
import {
  ICreateFormFields,
  TCreateProductFx,
  TGetProductFx,
  TUpdateProductFx,
} from '../types';
import { productsCreateRules } from '../lib/rules';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { productsApi } from 'entities/Products/api';
import { servicesModel } from 'entities/Services/model';
import { endPoints } from 'shared/config/endPoints';
import { replace } from 'shared/lib/redirect';
import { EToastType } from 'shared/types/toast';
import { pending, spread } from 'patronum';
import { showToastEvent } from 'shared/model/toast';
import { productsModel } from 'entities/Products/model';
import { prepareRequestParams } from '../lib/prepareRequestParams';
import { IProduct } from 'shared/types/api/products';

const resetAll = createEvent();

const $createForm = createForm<ICreateFormFields>({
  fields: {
    name: {
      init: '',
      rules: [rules.required()],
    },
    price: {
      init: '',
      rules: [rules.required()],
    },
    description: {
      init: '',
    },
    image: {
      init: null,
      rules: [productsCreateRules.isValidImage()],
    },
  },
  validateOn: ['change', 'submit'],
});

const createProductFx = createEffect<TCreateProductFx>(({ shopId, fields }) => {
  const formData = prepareRequestParams(fields);
  return productsApi.createProduct(shopId, formData);
});

const resetProductId = createEvent();
const setProductId = createEvent<string>();
const $productId = createStore<string>(null).reset(resetProductId);

sample({
  clock: $createForm.formValidated,
  source: {
    service: servicesModel.selectedService.stores.$service,
    productId: $productId,
  },
  filter: ({ productId }) => !Boolean(productId),
  fn: ({ service }, fields) => ({
    shopId: service.id,
    fields,
  }),
  target: createProductFx,
});

sample({
  clock: createProductFx.doneData,
  fn: (res) => res.data,
  target: productsModel.products.events.pushProduct,
});

sample({
  clock: createProductFx.doneData,
  fn: () => ({
    replace: { pathname: endPoints.PRODUCTS, search: '' },
    showToast: {
      toastParams: {
        type: EToastType.Success,
        messageKey: 'Товар создан!',
      },
    },
  }),
  target: spread({
    targets: {
      replace,
      showToast: showToastEvent,
    },
  }),
});

sample({
  clock: setProductId,
  source: $productId,
  filter: (currentProductId, newProductId) =>
    Boolean(newProductId && newProductId !== currentProductId),
  fn: (_, newProductId) => newProductId,
  target: $productId,
});

const getProductFx = createEffect<TGetProductFx>(productsApi.getProduct);
const resetProduct = createEvent();
const $product = createStore<IProduct>(null).reset(resetProduct);

const updateProductFx = createEffect<TUpdateProductFx>(
  ({ shopId, productId, fields }) => {
    const formData = prepareRequestParams(fields);
    return productsApi.updateProduct(shopId, productId, formData);
  },
);

sample({
  clock: $productId,
  source: servicesModel.selectedService.stores.$service,
  filter: (_, productId) => Boolean(productId),
  fn: (service, productId) => ({ shopId: service.id, productId }),
  target: getProductFx,
});

sample({
  clock: getProductFx.doneData,
  fn: (res) => res.data,
  target: $product,
});

sample({
  clock: getProductFx.doneData,
  fn: (res): ICreateFormFields => ({
    name: res.data.name,
    price: res.data.price.toString(),
    description: res.data.description,
    image: null,
  }),
  target: $createForm.setForm,
});

sample({
  clock: getProductFx.fail,
  fn: ({ params }) => ({
    replace: { pathname: endPoints.PRODUCTS, search: '' },
    showToast: {
      toastParams: {
        type: EToastType.Error,
        messageKey: `Товар с ID ${params.productId} не обнаружен`,
      },
    },
  }),
  target: spread({
    targets: {
      replace,
      showToast: showToastEvent,
    },
  }),
});

sample({
  clock: $createForm.formValidated,
  source: {
    service: servicesModel.selectedService.stores.$service,
    productId: $productId,
  },
  filter: ({ productId }) => Boolean(productId),
  fn: ({ service, productId }, fields) => ({
    shopId: service.id,
    productId,
    fields,
  }),
  target: updateProductFx,
});

sample({
  clock: updateProductFx.doneData,
  fn: (res) => res.data,
  target: productsModel.products.events.updateProduct,
});

sample({
  clock: updateProductFx.doneData,
  fn: () => ({
    replace: { pathname: endPoints.PRODUCTS, search: '' },
    showToast: {
      toastParams: {
        type: EToastType.Success,
        messageKey: 'Данные товара обновлены!',
      },
    },
  }),
  target: spread({
    targets: {
      replace,
      showToast: showToastEvent,
    },
  }),
});

const $isProductPending = pending({
  effects: [createProductFx, updateProductFx],
});

sample({
  clock: resetAll,
  target: [$createForm.reset, resetProductId, resetProduct],
});

export const form = {
  stores: {
    $createForm,
    $product,
  },
  events: {
    setProductId,
    resetAll,
  },
  pendings: {
    $isProductPending,
  },
};
