import React, { FC } from 'react';
import { sharedCss } from 'shared/css';
import { homeCss } from './style';
import { Card } from 'entities/Statistics/ui/Card';
import { useUnit } from 'effector-react';
import { statisticsModel } from 'entities/Statistics/model';
import { TopProducts } from './TopProducts';
import { useHistory } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';

export const Home: FC = () => {
  const history = useHistory();
  const [stats] = useUnit([statisticsModel.stats.stores.$data]);

  const onClickOrders = () => {
    history.push(endPoints.ORDERS);
  };

  return (
    <div className={sharedCss.pageContainer}>
      <div className={homeCss.grid}>
        <Card
          titleKey="Кол-во пользователей"
          value={stats?.main?.users ?? 0}
          idIcon="icUsers"
        />
        <Card
          titleKey="Всего просмотров"
          value={stats?.main?.views ?? 0}
          idIcon="icView"
        />
        <Card
          titleKey="Заказов"
          value={stats?.orders ?? 0}
          idIcon="icBag"
          onClick={onClickOrders}
        />
      </div>
      <TopProducts data={stats?.top_views_products ?? []} />
    </div>
  );
};
