import React, { FC } from 'react';
import { tabsCss } from './style';
import { ITabsProps } from './types';

export const Tabs: FC<ITabsProps> = ({ field }) => {
  const onClick = (type) => () => {
    field.value !== type && field.onChange(type);
  };

  return (
    <div className={tabsCss.container}>
      <button
        onClick={onClick('csv_text')}
        data-is-active={field.value === 'csv_text'}>
        Текст
      </button>
      <button
        onClick={onClick('csv_file')}
        data-is-active={field.value === 'csv_file'}>
        Файл
      </button>
    </div>
  );
};
