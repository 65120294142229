import React, { FC } from 'react';
import { IBoxHeaderProps } from './types';
import { Text } from 'shared/ui/atoms/Text';
import { boxHeaderCss } from './style';

export const BoxHeader: FC<IBoxHeaderProps> = ({
  icon,
  title,
  className,
  titleClassName = '',
  rightContent,
  hideDivider,
  isModal,
}) => {
  return (
    <div
      className={`${boxHeaderCss.container} ${
        hideDivider && boxHeaderCss.hideDivider
      } ${className ?? ''}`}>
      <div className={boxHeaderCss.titleBox}>
        {icon && icon}
        <Text
          size="h3"
          weight={isModal ? 'bold' : '500'}
          className={titleClassName}>
          {title}
        </Text>
      </div>
      {rightContent}
    </div>
  );
};
