import { useUnit } from 'effector-react';
import { authModel } from 'entities/Auth/model';
import { servicesModel } from 'entities/Services/model';
import { useEffect } from 'react';

export const useInitAuthorizedRequests = () => {
  const token = useUnit(authModel.token.stores.$token);

  useEffect(() => {
    if (token) {
      servicesModel.services.events.getServices();
    }
  }, [token]);
};
