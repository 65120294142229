import React from 'react';
import { imageCss } from './style';
import { IImage } from './types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const Image: React.FC<IImage> = React.memo(
  ({
    src,
    onClick,
    onLoad,
    alt,
    id,
    srcWebp,
    visibleByDefault = true,
    effect = 'opacity',
    className,
  }) => {
    return (
      <div className={`${imageCss.wrapper} ${className ?? ''}`}>
        {srcWebp && <source type="image/webp" srcSet={srcWebp} />}
        <LazyLoadImage
          id={id}
          src={src}
          alt={alt}
          effect={effect}
          visibleByDefault={visibleByDefault}
          onClick={onClick}
          afterLoad={onLoad}
          className={imageCss.lazyLoadImg}
          placeholder={<span className={imageCss.placeholder} />}
        />
      </div>
    );
  },
);
