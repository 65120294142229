import React, { FC } from 'react';
import { Products } from 'pages/Products';
import { Redirect, Route, Switch } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { ProductsCreate } from 'pages/ProductsCreate';
import { ProductsView } from 'pages/ProductsView';

export const ProductsRoutes: FC = () => {
  return (
    <Switch>
      <Route exact path={endPoints.PRODUCTS}>
        <Products />
      </Route>
      <Route exact path={[endPoints.PRODUCT_CREATE, endPoints.PRODUCT_EDIT]}>
        <ProductsCreate />
      </Route>
      <Route exact path={endPoints.PRODUCT_VIEW}>
        <ProductsView />
      </Route>
      <Redirect to={endPoints.PRODUCTS} />
    </Switch>
  );
};
