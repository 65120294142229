import { useUnit } from 'effector-react';
import React, { FC } from 'react';
import { themeModel } from 'shared/theme/model/theme';
import { Text } from 'shared/ui/atoms/Text';
import { themeButtonCss } from './style';
import { EThemeTypes } from 'shared/config/enums';

export const ThemeButton: FC = () => {
  const [theme] = useUnit([themeModel.stores.$theme]);

  const onToggle = () => {
    themeModel.events.toggleTheme();
  };

  return (
    <button
      className={themeButtonCss.button}
      data-is-dark-theme={theme.mode === EThemeTypes.dark}
      onClick={onToggle}
    />
  );
};
