import { createEffect, createEvent, createStore, sample } from 'effector';
import { IConfig } from 'shared/types/api/config';
import { TGetConfigFx } from '../types';
import { configApi } from '../api';
import { servicesModel } from 'entities/Services/model';

const resetAll = createEvent();

const $data = createStore<IConfig>(null).reset(resetAll);

const getConfig = createEvent();
const getConfigFx = createEffect<TGetConfigFx>(configApi.getConfig);

const $isPending = getConfigFx.pending;

sample({
  clock: getConfig,
  source: servicesModel.selectedService.stores.$service,
  filter: Boolean,
  fn: (service) => service.id,
  target: getConfigFx,
});

sample({
  clock: getConfigFx.doneData,
  fn: (res) => res.data,
  target: $data,
});

export const config = {
  stores: {
    $data,
  },
  events: {
    getConfig,
    resetAll,
  },
  pendings: {
    $isPending,
  },
};
