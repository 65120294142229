import { API } from 'shared/api/executor';
import { TGetStatistics } from '../types';
import { GET_STATISTICS } from 'shared/api/endpoints';

const getStatistics: TGetStatistics = (shopId) =>
  API().get(GET_STATISTICS(shopId));

export const statisticsApi = {
  getStatistics,
};
