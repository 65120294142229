import { TStrOrInt } from '../common';

export interface IOrder {
  id: string;
  user_id: string;
  products_amount: number;
  total_sum: number;
  merchant_id: string;
  service_id: string;
  status: EOrderStatus;
  products: IOrderProduct[];
}

export interface IOrderProduct {
  _id: string;
  name: string;
  description: string;
  price: number;
  image: string;
  category_id: string;
  service_id: string;
  merchant_id: string;
  amount: number;
  total_sum: number;
}

export enum EOrderStatus {
  ALL = 'all',
  CART = 'cart',
  PAID = 'paid',
  READY = 'ready',
  DONE = 'done',
  CANCELED = 'canceled',
}

export interface IGetOrdersParams {
  shopId: string;
  filters?: {
    status?: TStrOrInt;
  };
}

export interface IGetOrderParams {
  shopId: string;
  id: string;
}

export interface IUpdateOrderStatusParams {
  shopId: string;
  id: string;
  params: {
    status: string;
  };
}
