import React, { FC } from 'react';
import { WrapperSvg } from './style';
import { ESpriteNames, ISvgProps } from './types';
import defaultSprite from 'shared/assets/icons/sprite.svg?url';
import toastSprite from 'shared/assets/icons/toast/toastSprite.svg?url';
import componentSprite from 'shared/assets/icons/component/sprite.svg?url';

export const Svg: FC<ISvgProps> = ({
  idIcon,
  className = '',
  onClick,
  width,
  height,
  spriteName = ESpriteNames.DEFAULT,
  id,
}) => {
  const getCurrentSprite = () => {
    switch (spriteName) {
      case ESpriteNames.TOAST:
        return toastSprite;
      case ESpriteNames.COMPONENT:
        return componentSprite;
      default:
        return defaultSprite;
    }
  };

  return (
    <WrapperSvg
      id={id}
      onClick={onClick}
      className={`svg-icon ${className}`}
      width={width}
      height={height}>
      <use xlinkHref={`${getCurrentSprite()}#${idIcon}`} />
    </WrapperSvg>
  );
};
