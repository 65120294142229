import { createEffect, createEvent, restore, sample } from 'effector';

import { endPoints } from 'shared/config/endPoints';
import { IRedirect } from './types';
import { createGate } from 'effector-react';
import { debug } from 'patronum';

const HistoryGate = createGate();

const resetRoute = createEvent();

const redirect = createEvent<endPoints | IRedirect | string>();
const $route = restore(redirect, null).reset(resetRoute);

const replace = createEvent<{ pathname: string; search: string }>();
const $replaceRoute = restore(replace, null).reset(resetRoute);

const resetSearchParams = createEvent();
const resetSearchParamsFx = createEffect((history) => {
  history.push({});
});

sample({
  clock: resetSearchParams,
  source: HistoryGate.state,
  fn: (history) => history,
  target: resetSearchParamsFx,
});

export {
  redirect,
  $route,
  resetRoute,
  $replaceRoute,
  replace,
  resetSearchParams,
  HistoryGate,
};
