import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const label = css`
  display: block;
  margin-bottom: 10px;
`;

const defaultSelect = css`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  .react-select {
    &__control {
      position: relative;
      z-index: 3000;
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid;
      padding: 0 7px;
      ${theming((theme) => ({
        caretColor: theme.text.secondaryText,
        color: theme.text.secondaryText,
        borderColor: theme.stroke.primary,
        background: theme.object.primaryTextfield,
      }))}
      &--is-focused {
        outline: none;
        z-index: 5000;
        ${theming((theme) => ({
          borderColor: theme.stroke.accent,
        }))}
        svg {
          ${theming((theme) => ({
            color: theme.icon.primary,
          }))}
        }
      }
      &:not(&--is-focused):hover {
        ${theming((theme) => ({
          borderColor: theme.stroke.accent,
        }))}
      }
      &--is-disabled {
        opacity: 0.7;
      }
      svg {
        ${theming((theme) => ({
          color: theme.icon.primary,
        }))}
      }
    }
    &__value-container--is-multi {
      flex-wrap: unset;
    }
    &__menu {
      margin: 0;
      border-radius: 0 0 14px 14px;
      padding-top: 15px;
      top: calc(100% - 15px);
      bottom: auto;
      z-index: 4000;
      width: 99.5%;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      box-shadow: none;
      border: 1px solid;
      ${theming((theme) => ({
        borderColor: theme.stroke.primary,
        background: theme.object.primaryTextfield,
      }))}
    }
    &__menu-list {
      padding: 0;
      max-height: 150px;
      overflow-y: auto;
    }
    &__option {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      cursor: pointer;
      text-align: left;
      ${theming((theme) => ({
        color: theme.text.secondaryText,
      }))}

      &:last-child {
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
      }

      &:hover {
        ${theming((theme) => ({
          background: theme.object.primaryHoverTextfield,
        }))}
      }
    }
    &__single-value {
      display: flex;
      align-items: center;

      ${theming((theme) => ({
        color: theme.text.secondaryText,
      }))}
    }
    &__input-container,
    &__placeholder {
      ${theming((theme) => ({
        color: theme.text.secondaryText,
      }))}
    }
    &__multi-value {
      border-radius: 7px;
      padding: 5px 10px;
      ${theming((theme) => ({
        color: theme.text.secondaryText,
      }))}
      ${theming((theme) => ({
        background: theme.object.primaryTextfield,
      }))}

      &__value {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
      }

      &__remove {
        display: inline;
        margin-left: 10px;
        border-radius: 50%;
        ${theming((theme) => ({
          background: theme.object.primaryTextfield,
        }))}
        svg {
          width: 8px;
          height: 8px;

          ${theming((theme) => ({
            color: theme.text.secondaryText,
          }))}
        }
      }
    }
    &__indicator {
      padding: 0;
    }
    &__indicator-separator {
      display: none;

      ${theming((theme) => ({
        color: theme.text.secondaryText,
      }))}
    }
  }
`;

const defaultWrapper = css`
  max-width: 100%;
  &[data-height='30'] {
    & .react-select__control {
      min-height: 30px;
      height: 30px;

      &__option {
        height: 30px;
      }
    }
  }
  &[data-height='35'] {
    & .react-select {
      &__control {
        min-height: 35px;
        height: 35px;
      }
      &__option {
        height: 35px;
      }
    }
  }
  &[data-height='40'] {
    & .react-select {
      &__control {
        min-height: 40px;
        height: 40px;
      }
      &__option {
        height: 40px;
      }
    }
  }
  &[data-height='50'] {
    & .react-select {
      &__control {
        min-height: 50px;
        height: 50px;
      }
      &__option {
        height: 50px;
      }
    }
  }
  &[data-border-radius='14'] {
    & .react-select__control {
      border-radius: 14px;
    }
  }
  &[data-border-radius='8'] {
    & .react-select__control {
      border-radius: 8px;
    }

    .${defaultSelect} {
      .react-select {
        &__menu {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }
  &[data-border-radius='4'] {
    & .react-select__control {
      border-radius: 4px;
    }

    .${defaultSelect} {
      .react-select {
        &__menu {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }
  &[data-is-error='true'] {
    .react-select__control {
      ${theming((theme) => ({
        borderColor: theme.stroke.error,
        background: theme.object.primaryErrorTextfield,
      }))}
    }
  }
  &[data-is-with-arrow='false'] {
    .react-select__indicators {
      display: none;
    }
  }
`;

const imageDefault = css`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const selectCss = {
  defaultSelect,
  defaultWrapper,
  label,
  imageDefault,
};
