export const defaultSelectStyle = {
  option: () => ({
    padding: '0 15px',
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      borderRadius: 8,
      backgroundColor: 'transparent',
    };
  },
  multiValueLabel: () => {
    return {
      paddingLeft: 0,
      paddingRight: 0,
    };
  },
  multiValueRemove: () => {
    return {
      display: 'none',
    };
  },
  clearIndicator: () => {
    return {
      display: 'none',
    };
  },
};
