import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const button = css`
  margin-top: 20px;
  width: 100%;

  svg {
    margin-right: 10px;
    ${theming((theme) => ({ color: theme.colors.white }))}
  }
`;

export const createButtonCss = {
  button,
};
