import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import Modal from 'react-modal';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from 'processes/routes';
import { checkOnlineBrowserState } from 'shared/model/connection';
import { globalClass, toastContainerClass } from 'shared/theme/GlobalStyles';
import { themeModel } from 'shared/theme/model/theme';
import { useMetaThemeColor } from './lib/useMetaThemeColor';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAdaptiveScreen } from './lib/useAdaptiveScreen';
import { useInitCacheStores } from './lib/useInitCacheStores';
import { useInitAuthorizedRequests } from './lib/useInitAuthorizedRequests';

Modal.setAppElement('#root');

const App = () => {
  const activeTheme = useUnit(themeModel.stores.$theme);
  useMetaThemeColor(activeTheme.theme);
  useAdaptiveScreen();
  useInitCacheStores();
  useInitAuthorizedRequests();

  useEffect(() => {
    checkOnlineBrowserState();
  }, []);

  return (
    <div className={`${globalClass} ${activeTheme.mode}`}>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        className={toastContainerClass}
        closeButton={false}
      />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
};

export default App;
