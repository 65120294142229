import { theming } from 'shared/theme';
import { css } from '@linaria/core';

export const checkboxClass = css`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;

  &[data-is-secondary='true'] {
    border: 1px solid;
    ${theming((theme) => ({
      borderColor: theme.stroke.primary,
      boxShadow: `0 2px 5px ${theme.commonBoxShadow}`,
      background: theme.object.secondaryObject,
    }))}
  }

  &[data-is-secondary='false'] {
    ${theming((theme) => ({
      background: theme.object.primaryObject,
      boxShadow: `0 5px 15px ${theme.commonBoxShadow}`,
    }))}
  }

  &[data-is-large='true'] {
    width: 30px;
    height: 30px;
    border-radius: 10px;
  }

  &[data-has-border='true'] {
    border: 1px solid;

    ${theming((theme) => ({
      borderColor: theme.stroke.primary,
    }))}
  }
`;

export const wrapperClass = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
`;

export const svgWrapperClass = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${theming((theme) => ({
    background: theme.button.filled.primary.background.default,
  }))};

  svg {
    ${theming((theme) => ({
      color: theme.text.primaryButton,
    }))}
  }
`;

export const inputClass = css`
  display: none;

  & + .${checkboxClass} .${svgWrapperClass} {
    transform: scale(0);
    transition: 0.1s linear;
  }

  &:checked + .${checkboxClass} .${svgWrapperClass} {
    transform: scale(1);
  }

  &[data-is-secondary='true']:checked + .${checkboxClass} {
    box-shadow: none;
  }
`;

export const labelClass = css`
  font-size: 16px;
  line-height: 26px;
`;

export const checkedIconClass = css`
  width: 13px;
  height: 10px;
`;

export const checkMinusIconClass = css`
  width: 24px;
  height: 24px;
`;
