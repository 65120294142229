import { useForm } from 'effector-forms';
import React, { FC } from 'react';
import { sharedCss } from 'shared/css';
import { TOnChange, TOnDrop, TOnSubmit } from 'shared/types/common';
import { Textarea } from 'shared/ui/atoms/Textarea';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { importModel } from './model';
import { CheckBox } from 'shared/ui/atoms/CheckBox';
import { importCss } from './style';
import { Button } from 'shared/ui/atoms/Button';
import { Tabs } from './ui/Tabs';
import { FileUpload } from './ui/FileUpload';
import { showToast } from 'shared/lib/showToast';
import { EToastType } from 'shared/types/toast';

export const Import: FC = () => {
  const { fields, values, errorText, submit } = useForm(
    importModel.form.stores.$importForm,
  );

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onToggleCheckbox = () => {
    fields.skip_headers.onChange(!values.skip_headers);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();

    if (values.type === 'csv_file' && !values.file) {
      showToast({
        toastParams: {
          type: EToastType.Error,
          messageKey: 'importCsv.fileRequired',
        },
      });
    }

    submit();
  };

  return (
    <div className={`${sharedCss.pageContainer} ${importCss.container}`}>
      <BoxHeader title="Импорт CSV" />
      <Tabs field={fields.type} />
      <form
        className={`${sharedCss.boxContainer} ${importCss.content}`}
        onSubmit={onSubmit}>
        {values.type === 'csv_text' ? (
          <Textarea
            classWrapper={importCss.textareaWrapper}
            classInput={importCss.textarea}
            inputProps={{
              name: 'text',
              onChange,
              defaultValue: values.text as string,
            }}
            errorKey={errorText('text')}
          />
        ) : (
          <FileUpload field={fields.file} />
        )}
        <CheckBox
          name="skip_headers"
          isChecked={values.skip_headers}
          onChange={onToggleCheckbox}
          className={importCss.checkbox}
          styleType="secondary"
          labelKey="Исключить заголовки"
        />
        <Button type="submit" className={importCss.button}>
          Импортировать
        </Button>
      </form>
    </div>
  );
};
