import React, { FC, useEffect } from 'react';
import { sharedCss } from 'shared/css';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { orderDetailsModel } from './model';
import { useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { orderDetailsCss } from './style';
import { useGlobalScrollDisabled } from 'shared/lib/useGlobalScrollDisabled';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { Content } from './ui/Content';

export const OrderDetails: FC = () => {
  useGlobalScrollDisabled();
  const params = useParams<{ id: string }>();
  const isPending = useUnit(orderDetailsModel.order.pendings.$isPending);

  useEffect(() => {
    orderDetailsModel.order.events.getOrder(params.id);

    return () => {
      orderDetailsModel.order.events.resetData();
    };
  }, []);

  return (
    <div className={`${sharedCss.boxContainer} ${orderDetailsCss.container}`}>
      <BoxHeader title={`Заказ #${params?.id}`} />
      {isPending ? <SpinnerBlock size={40} /> : <Content />}
    </div>
  );
};
