import { createEffect, createEvent, createStore, sample } from 'effector';
import { productsApi } from 'entities/Products/api';
import { IProduct } from 'shared/types/api/products';
import { TGetProductFx } from '../types';
import { servicesModel } from 'entities/Services/model';
import { endPoints } from 'shared/config/endPoints';
import { EToastType } from 'shared/types/toast';
import { replace } from 'shared/lib/redirect';
import { showToastEvent } from 'shared/model/toast';
import { spread } from 'patronum';

const reset = createEvent();
const $data = createStore<IProduct>(null).reset(reset);

const getProduct = createEvent<string>();
const getProductFx = createEffect<TGetProductFx>(productsApi.getProduct);

sample({
  clock: getProduct,
  source: servicesModel.selectedService.stores.$service,
  filter: (_, productId) => Boolean(productId),
  fn: (service, productId) => ({ shopId: service.id, productId }),
  target: getProductFx,
});

sample({
  clock: getProductFx.doneData,
  fn: (res) => res.data,
  target: $data,
});

sample({
  clock: getProductFx.fail,
  fn: ({ params }) => ({
    replace: { pathname: endPoints.PRODUCTS, search: '' },
    showToast: {
      toastParams: {
        type: EToastType.Error,
        messageKey: `Товар с ID ${params.productId} не обнаружен`,
      },
    },
  }),
  target: spread({
    targets: {
      replace,
      showToast: showToastEvent,
    },
  }),
});

export const product = {
  stores: {
    $data,
  },
  events: {
    getProduct,
    reset,
  },
};
