import Axios, { AxiosError } from 'axios';
import { showToast } from 'shared/lib/showToast';
import { EToastType } from 'shared/types/toast';
import { TOKEN } from 'entities/Auth/config';
import { authModel } from 'entities/Auth/model';

export const APPLICATION_JSON = 'application/json';
export const FORM_DATA = 'multipart/form-data';

export const API = (baseURL: string = process.env.REACT_APP_API_HOST) => {
  const token = localStorage.getItem(TOKEN) as string;

  const instance = Axios.create({
    baseURL,
    timeout: 30000,
    headers: {
      Accept: APPLICATION_JSON,
      'Content-Type': APPLICATION_JSON,
      ...{ ...(token && { Authorization: `Bearer ${token}` }) },
    },
  });

  instance.interceptors.response.use(
    (response) => {
      console.log({
        method: response.config.url,
        data: response.data,
      });
      return response;
    },
    (error: AxiosError) => {
      console.error(error);
      if (error.response.status === 401) {
        showToast({
          toastParams: {
            type: EToastType.Error,
            messageKey: 'Session has expired',
          },
        });
        authModel.logout.events.logoutAccount();
      }

      return Promise.reject(error);
    },
  );

  return instance;
};
