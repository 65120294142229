import { Rule } from 'effector-forms';
import { IS_URL } from 'shared/config/regex';
import { isImageFile } from 'shared/lib/isImageFile';
import { isURL } from 'validator';

export const productsCreateRules = {
  isValidImage: function <T = any>(): Rule<T> {
    return {
      name: 'isValidImage',
      validator: (value) => ({
        isValid:
          !value ||
          (value instanceof File && isImageFile(value)) ||
          (typeof value === 'string' && IS_URL.test(value)),
        errorText:
          value instanceof File
            ? 'createProduct.errors.onlyImageAllowed'
            : 'createProduct.errors.invalidUrl',
      }),
    };
  },
};
