import { FC, Fragment } from 'react';
import { shopsListCss } from './style';
import { servicesModel } from 'entities/Services/model';
import { useUnit } from 'effector-react';
import { ServiceButton } from '../ServiceButton';
import { Text } from 'shared/ui/atoms/Text';
import { CreateButton } from '../CreateButton';

export const ServiceList: FC = () => {
  const [services] = useUnit([servicesModel.services.stores.$list]);

  return (
    <Fragment>
      <Text size="h1" weight="bold">
        Ваши приложения
      </Text>
      <Text size="h4" weight="400">
        Выберите приложение для того чтобы пользоваться системой
      </Text>
      <div className={shopsListCss.container}>
        {services?.map((servic) => (
          <ServiceButton key={servic.id} data={servic} />
        ))}
      </div>
      <CreateButton />
    </Fragment>
  );
};
