import { createEvent, createStore, sample } from 'effector';
import { throttle } from 'patronum';

const setIsMobile = createEvent<boolean>();
const $isMobile = createStore(window.innerWidth <= 768);

sample({
  clock: throttle({ source: setIsMobile, timeout: 500 }),
  target: $isMobile,
});

export const mobileModel = {
  stores: {
    $isMobile,
  },
  events: {
    setIsMobile,
  },
};
