import React, { useEffect } from 'react';
import { DefaultTheme } from 'shared/theme/types';

export const useMetaThemeColor = (theme: DefaultTheme) => {
  useEffect(() => {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute('content', theme.object.primaryScreen);
    document.querySelector('body').style.backgroundColor =
      theme.object.primaryScreen;
  }, [theme]);
};
