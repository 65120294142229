import React, { FC, useEffect } from 'react';
import { sharedCss } from 'shared/css';
import { productsCreateCss } from './style';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { Input } from 'shared/ui/atoms/Input';
import { Textarea } from 'shared/ui/atoms/Textarea';
import { useForm } from 'effector-forms';
import { productsCreateModel } from './model';
import { TOnChange, TOnSubmit } from 'shared/types/common';
import { Button } from 'shared/ui/atoms/Button';
import { useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';
import { ImageLoader } from './ui/ImageLoader';

export const ProductsCreate: FC = () => {
  const params = useParams<{ id }>();
  const [isPending] = useUnit([
    productsCreateModel.form.pendings.$isProductPending,
  ]);
  const { fields, values, errorText, submit } = useForm(
    productsCreateModel.form.stores.$createForm,
  );

  useEffect(() => {
    if (params?.id) {
      productsCreateModel.form.events.setProductId(params.id);
    }

    return () => {
      productsCreateModel.form.events.resetAll();
    };
  }, [params?.id]);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <div
      className={`${sharedCss.pageContainer} ${productsCreateCss.container}`}>
      <BoxHeader title="Создать товар" />
      <form className={productsCreateCss.form} onSubmit={onSubmit}>
        <div className={sharedCss.row}>
          <Input
            labelKey="Название"
            classWrapper={productsCreateCss.input}
            errorKey={errorText('name')}
            inputProps={{
              name: 'name',
              placeholder: 'Введите название товара',
              defaultValue: values.name,
              onChange,
            }}
          />
          <Input
            labelKey="Цена"
            classWrapper={productsCreateCss.input}
            errorKey={errorText('price')}
            inputProps={{
              name: 'price',
              type: 'number',
              placeholder: 'Введите цену товара',
              defaultValue: values.price,
              onChange,
            }}
          />
        </div>
        <Textarea
          labelKey="Описание"
          classInput={productsCreateCss.textarea}
          inputProps={{
            name: 'description',
            placeholder: 'Введите описание товара...',
            defaultValue: values.description,
            onChange,
          }}
        />
        <ImageLoader field={fields.image} />
        <Button
          isLoading={isPending}
          className={productsCreateCss.submitButton}>
          Сохранить
        </Button>
      </form>
    </div>
  );
};
