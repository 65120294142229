import React, { FC, useEffect, useState } from 'react';
import { sharedCss } from 'shared/css';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { Map } from './ui/Map';

export const Settings: FC = () => {
  const [width, setWidth] = useState<number>(0);

  const onContainerInit = (ref: HTMLDivElement) => {
    if (ref) {
      setWidth(ref.getBoundingClientRect().width);
    }
  };

  return (
    <div className={sharedCss.pageContainer}>
      <div ref={onContainerInit} className={sharedCss.boxContainer}>
        <BoxHeader title="Местоположение" />
        <Map width={width} />
      </div>
    </div>
  );
};
