import React, { FC } from 'react';
import { Text } from '../Text';
import { tableColumnCss } from './style';
import { TStrOrInt } from 'shared/types/common';
import { showToast } from 'shared/lib/showToast';
import { EToastType } from 'shared/types/toast';
import { ITableColumnProps } from './types';
import { Svg } from '../Svg';
import { Image } from '../Image';
import NoImage from 'shared/assets/images/noImage.jpg';
import { useTranslation } from 'react-i18next';

export const TableColumn: FC<ITableColumnProps> = ({
  value,
  hasCopy = false,
  isImage = false,
  i18n,
}) => {
  const { t } = useTranslation();
  
  const onCopy = (value: TStrOrInt) => () => {
    navigator.clipboard.writeText(value.toString());
    showToast({
      toastParams: {
        type: EToastType.Success,
        messageKey: 'common.copiedToClipboard',
      },
    });
  };

  return (
    <td>
      {isImage && <Image alt="" src={(value as string) || NoImage} />}
      {!isImage && (
        <Text color="secondary">
          {i18n?.path ? t(`${i18n.path}.${value}`) : value}
        </Text>
      )}
      {hasCopy && (
        <button className={tableColumnCss.copyButton} onClick={onCopy(value)}>
          <Svg idIcon="icCopy" width={24} height={24} />
        </button>
      )}
    </td>
  );
};
