import React, { FC } from 'react';
import { StyledSpinnerBlock } from './style';
import { ISpinnerBlock } from './types';

export const SpinnerBlock: FC<ISpinnerBlock> = ({
  size,
  activeColor,
  colorBgr,
  styled,
  className,
}) => {
  return (
    <StyledSpinnerBlock
      size={size}
      colorBgr={colorBgr}
      activeColor={activeColor}
      style={styled}
      className={className}>
      <div className='spinner' />
    </StyledSpinnerBlock>
  );
};
