import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICheckBox } from './types';
import { Svg } from 'shared/ui/atoms/Svg';
import { Text } from 'shared/ui/atoms/Text';
import {
  checkboxClass,
  inputClass,
  labelClass,
  svgWrapperClass,
  wrapperClass,
  checkedIconClass,
  checkMinusIconClass,
} from './style';
import { TOnChange } from 'shared/types/common';

export const CheckBox: FC<ICheckBox> = ({
  name,
  onLabelClick,
  className = '',
  wrapperClassName = '',
  isChecked = false,
  labelKey = '',
  onChange = () => {},
  labelElement = null,
  isLarge = false,
  styleType = 'primary',
  labelClassName = '',
  isCheckMinusIcon = false,
  checkBoxClassName = '',
  hasBorder,
  svgWrapperClassName = '',
  isDisabled = false,
  testId,
}) => {
  const { t } = useTranslation();

  const onChangeCheckBox: TOnChange = (e) => {
    onChange(e);
  };

  return (
    <label
      className={`${wrapperClass} ${wrapperClassName}`}
      htmlFor={name}
      onClick={onLabelClick}
      data-cy={testId}>
      <input
        checked={isChecked}
        data-is-secondary={styleType === 'secondary'}
        className={`${inputClass} ${className}`}
        onChange={onChangeCheckBox}
        type="checkbox"
        id={name}
        name={name}
        disabled={isDisabled}
      />

      <div
        className={`${checkboxClass} ${checkBoxClassName}`}
        data-is-large={isLarge}
        data-has-border={hasBorder}
        data-is-secondary={styleType === 'secondary'}>
        <div className={`${svgWrapperClass} ${svgWrapperClassName}`}>
          <Svg
            idIcon={isCheckMinusIcon ? 'icCheckMinus' : 'icCheck'}
            className={
              isCheckMinusIcon ? checkMinusIconClass : checkedIconClass
            }
          />
        </div>
      </div>

      {labelKey && (
        <Text className={`${labelClass} ${labelClassName}`}>{t(labelKey)}</Text>
      )}
      {labelElement}
    </label>
  );
};
