import React, { FC } from 'react';
import { ordersModel } from 'entities/Orders/model';
import { sharedCss } from 'shared/css';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { useUnit } from 'effector-react';
import { Table } from 'shared/ui/molecules/Table';
import { IOrder } from 'shared/types/api/orders';
import { ITableDataItem } from 'shared/ui/molecules/Table/types';
import { useHistory } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';

export const Cart: FC = () => {
  const history = useHistory();
  const orders = useUnit(ordersModel.cart.stores.$orders);
  const data: ITableDataItem<IOrder> = {
    headRows: [
      { name: 'ID' },
      { name: 'User ID' },
      { name: 'Кол-во товаров' },
      { name: 'Сумма' },
    ],
    bodyRows: orders,
  };

  const onView = (id: string) => {
    history.push(endPoints.CART_ORDER.replace(':id', id));
  };

  return (
    <div className={sharedCss.boxContainer}>
      <BoxHeader title="Корзина" />
      <Table<IOrder>
        data={data}
        keys={[
          { name: 'id', hasCopy: true },
          { name: 'user_id' },
          'products_amount',
          'total_sum',
        ]}
        mapKey="id"
        actions={{
          onView,
        }}
      />
    </div>
  );
};
