import React, { FC } from 'react';
import { IOrderProduct } from 'shared/types/api/orders';
import { Table } from 'shared/ui/molecules/Table';
import { ITableDataItem } from 'shared/ui/molecules/Table/types';
import { IProductsProps } from './types';
import { productsCss } from './style';

export const Products: FC<IProductsProps> = ({ data }) => {
  const tableData: ITableDataItem<IOrderProduct> = {
    headRows: [
      { name: 'ID', key: 'id' },
      { name: 'Фото', key: 'image' },
      { name: 'Название', key: 'name' },
      { name: 'Описание', key: 'description' },
      { name: 'Цена', key: 'price' },
      { name: 'Кол-во', key: 'amount' },
      { name: 'Общая сумма', key: 'total_sum' },
    ],
    bodyRows: data ?? [],
  };
  return (
    <div className={productsCss.container}>
      <Table<IOrderProduct>
        data={tableData}
        keys={[
          '_id',
          { name: 'image', isImage: true },
          'name',
          'description',
          'price',
          'amount',
          'total_sum',
        ]}
        mapKey="_id"
      />
    </div>
  );
};
