import React, { FC, lazy } from 'react';
import { confirmModalModel } from 'features/ConfirmModal/model';
import { LazyComponent } from 'shared/ui/molecules/LazyComponent';
import { createApplicationModalModel } from 'features/CreateApplicationModal/model';

const ConfirmModal = lazy(() =>
  import('features/ConfirmModal').then(({ ConfirmModal }) => ({
    default: ConfirmModal,
  })),
);

const CreateApplicationModal = lazy(() =>
  import('features/CreateApplicationModal').then(
    ({ CreateApplicationModal }) => ({
      default: CreateApplicationModal,
    }),
  ),
);

export const MainModals: FC = () => {
  return (
    <>
      <LazyComponent
        $store={confirmModalModel.modal.$store}
        withAnimation
        component={ConfirmModal}
      />
      <LazyComponent
        $store={createApplicationModalModel.modal.$store}
        withAnimation
        component={CreateApplicationModal}
      />
    </>
  );
};
