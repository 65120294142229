import { useHistory } from 'react-router-dom';
import { useGate, useUnit } from 'effector-react';
import { $replaceRoute, $route, HistoryGate, resetRoute } from '../redirect';
import { useEffect } from 'react';

export const useRoute = () => {
  const history = useHistory();
  const [path, replace] = useUnit([$route, $replaceRoute]);
  useGate(HistoryGate, history);

  useEffect(() => {
    if (path) {
      if (typeof path === 'string') history.push(path);
      if (typeof path === 'object') history.push(path.path, path.state);
      resetRoute();
    }
  }, [path]);

  useEffect(() => {
    if (replace) history.replace(replace);
  }, [replace]);
};
