import { Rule } from 'effector-forms';

export const importRules = {
  requiredText: function <T = any>(): Rule<T> {
    return {
      name: 'required',
      validator: (value, form) => {
        return {
          isValid: form.type === 'csv_text' ? Boolean(value) : true,
          errorText: 'errors.isRequired',
        };
      },
    };
  },
  requiredFile: function <T = any>(): Rule<T> {
    return {
      name: 'required',
      validator: (value, form) => {
        return {
          isValid: form.type === 'csv_file' ? Boolean(value) : true,
          errorText: 'errors.isRequired',
        };
      },
    };
  },
};
