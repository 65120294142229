import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const container = css`
  position: relative;
`;

const saveBtn = css`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
`;

const addressBox = css`
  padding: 15px 10px;

  &[data-is-new='true'] {
    ${theming((theme) => ({
      backgroundColor: setAlpha(theme.colors.green, 0.1),
    }))}
  }
`;

export const mapCss = {
  container,
  saveBtn,
  addressBox,
};
