import React, { FC } from 'react';
import { IButtonProps } from './types';
import { buttonCss } from './style';
import { SpinnerBlock } from '../SpinnerBlock';

export const Button: FC<IButtonProps> = ({
  children,
  appearance = 'filled',
  styleType = 'primary',
  type = 'submit',
  isDisabled,
  className,
  style,
  isLoading,
  onClick,
}) => {
  return (
    <button
      className={`${buttonCss.button} ${className}`}
      data-is-style={styleType}
      data-is-appearance={appearance}
      disabled={isDisabled || isLoading}
      type={type}
      onClick={onClick}
      {...(style && { style })}>
      {isLoading ? <SpinnerBlock size={20} /> : children}
    </button>
  );
};
