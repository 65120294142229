import React, { Fragment } from 'react';
import { tableCss } from './style';
import { ITableProps } from './types';
import { Text } from 'shared/ui/atoms/Text';
import { Svg } from 'shared/ui/atoms/Svg';
import { Button } from 'shared/ui/atoms/Button';
import { capitalize } from 'shared/lib/capitalize';
import { TableColumn } from 'shared/ui/atoms/TableColumn';
import { TableFilter } from '../TableFilter';

export const Table = <T,>({
  className,
  data,
  keys,
  mapKey,
  actions,
  renderFilters,
  hasFilters,
  hasActions,
  sortValues,
  hasPagination = false,
  onClickSort,
  onChangeSelect,
  onChangeInput,
}: ITableProps<T>) => {
  const isEmptyList = !Boolean(data.bodyRows.length);
  const isActionVisible = hasActions && !isEmptyList;

  const onSort = (key: string) => () => {
    onClickSort(key);
  };

  return (
    <div className={className ?? ''}>
      {renderFilters && <div className={tableCss.filters}>{renderFilters}</div>}
      <div className={tableCss.tableContainer}>
        <table width="100%">
          <thead>
            <tr
              className={tableCss.head}
              data-has-filters={Boolean(renderFilters)}>
              {data.headRows.map((item) => (
                <Fragment key={item.name}>
                  <th
                    data-is-sort-enabled={item?.hasSort}
                    data-is-sort-active={sortValues?.[item.name]}
                    {...(item?.hasSort && { onClick: onSort(item.name) })}>
                    <div>
                      <Text>{capitalize(item.name)}</Text>
                      {item?.hasSort && (
                        <Svg
                          className={tableCss.sortIcon}
                          idIcon="icSort"
                          width={16}
                          height={16}
                        />
                      )}
                    </div>
                  </th>
                </Fragment>
              ))}
              {isActionVisible && (
                <th>
                  <Text>Actions</Text>
                </th>
              )}
            </tr>
            {hasFilters && (
              <tr
                className={tableCss.filtersRow}
                data-is-actions-visible={isActionVisible}>
                {data.headRows.map((item) => (
                  <th key={item.key}>
                    {item?.filter && item.name.toLowerCase() !== 'actions' && (
                      <TableFilter
                        name={item.key}
                        data={item.filter}
                        onChangeSelect={onChangeSelect(item.key)}
                        onChangeInput={onChangeInput}
                      />
                    )}
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {data.bodyRows.map((row) => (
              <tr key={row?.[mapKey]}>
                {keys.map((key) => {
                  const parsedKey = typeof key === 'string' ? key : key.name;
                  return (
                    <TableColumn
                      key={`${row?.[mapKey]}_${parsedKey}}`}
                      value={row?.[parsedKey]}
                      {...(typeof key === 'object' && {
                        hasCopy: Boolean(key?.hasCopy),
                        isImage: Boolean(key?.isImage),
                        i18n: key?.i18n,
                      })}
                    />
                  );
                })}
                {actions && (
                  <td className={tableCss.actionsTd}>
                    {actions?.custom?.position === 'start' &&
                      actions?.custom?.renderContent(row?.[mapKey])}
                    {actions?.onView && (
                      <Button
                        appearance="outlined"
                        styleType="primary"
                        onClick={() => actions.onView(row?.[mapKey])}>
                        Просмотр
                      </Button>
                    )}
                    {actions.onEdit && (
                      <Button
                        appearance="outlined"
                        styleType="secondary"
                        onClick={() => actions.onEdit(row?.[mapKey])}>
                        Изменить
                      </Button>
                    )}
                    {actions.onDelete && (
                      <Button
                        appearance="outlined"
                        styleType="warning"
                        onClick={() => actions.onDelete(row?.[mapKey])}>
                        Удалить
                      </Button>
                    )}
                    {actions?.custom?.position === 'end' &&
                      actions?.custom?.renderContent(row?.[mapKey])}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {isEmptyList && (
          <div className={tableCss.emptyList}>
            <Text size="h4">Нет данных</Text>
          </div>
        )}
        {hasPagination && (
          <div className={tableCss.pagination}>
            <Text color="secondary">Показано 1 - 10 из 100 элементов</Text>
            <div className={tableCss.paginationButtons}>
              <button className={tableCss.paginationButton} disabled={true}>
                <Svg idIcon="icArrowDown" width={24} height={24} />
              </button>
              <Text color="secondary">1 of 10</Text>
              <button className={tableCss.paginationButton}>
                <Svg idIcon="icArrowDown" width={24} height={24} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
