import { createForm } from 'effector-forms';
import { IImportFormFields, TImportFx } from '../types';
import { createEffect, sample } from 'effector';
import { importRules } from '../lib/rules';
import { importCsv } from '../api';
import { servicesModel } from 'entities/Services/model';
import { EToastType } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';
import { endPoints } from 'shared/config/endPoints';
import { spread } from 'patronum';
import { redirect } from 'shared/lib/redirect';
import { productsModel } from 'entities/Products/model';

const $importForm = createForm<IImportFormFields>({
  fields: {
    type: {
      init: 'csv_text',
    },
    text: {
      init: '',
      rules: [importRules.requiredText()],
    },
    file: {
      init: null,
      rules: [importRules.requiredFile()],
    },
    skip_headers: {
      init: false,
    },
  },
  validateOn: ['change', 'submit'],
});

const importFx = createEffect<TImportFx>(async ({ fields, shopId }) => {
  const formData = new FormData();
  formData.append('type', fields.type);
  formData.append('skip_headers', JSON.stringify(fields.skip_headers));
  formData.append(
    'data',
    fields.type === 'csv_text' ? fields.text : fields.file,
  );
  return await importCsv(shopId, formData);
});

const $isImportPending = importFx.pending;

sample({
  clock: $importForm.formValidated,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, fields) => ({
    shopId: service.id,
    fields,
  }),
  target: importFx,
});

sample({
  clock: importFx.doneData,
  fn: () => ({ isForce: true }),
  target: productsModel.products.events.getProducts,
});

sample({
  clock: importFx.doneData,
  filter: (res) => Boolean(!res.data.hasError.length && res.data.result),
  fn: (res) => ({
    toast: {
      toastParams: {
        type: EToastType.Success,
        messageKey: 'importCsv.success',
        translateParams: {
          value: res.data.importedItems.toString(),
        },
      },
    },
    redirectTo: endPoints.PRODUCTS,
  }),
  target: spread({
    targets: {
      toast: showToastEvent,
      redirectTo: redirect,
    },
  }),
});

sample({
  clock: importFx.doneData,
  filter: (res) => Boolean(res.data.hasError.length || !res.data.result),
  fn: (res) => ({
    toastParams: {
      type: EToastType.Error,
      messageKey: res?.data?.message || 'importCsv.hasError',
    },
  }),
  target: showToastEvent,
});

sample({
  clock: importFx.doneData,
  target: $importForm.reset,
});

export const form = {
  stores: {
    $importForm,
  },
  pendings: {
    $isImportPending,
  },
};
