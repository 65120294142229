import { createEffect, createEvent, sample } from 'effector';
import { loginApi } from '../api';
import { TLoginToAccountFx } from '../types';
import { ILoginParams } from 'shared/types/api/auth';
import { profileModel } from 'entities/Profile/model';
import { authModel } from 'entities/Auth/model';
import { endPoints } from 'shared/config/endPoints';
import { replace } from 'shared/lib/redirect';

const loginToAccount = createEvent<ILoginParams>();
const loginToAccountFx = createEffect<TLoginToAccountFx>(loginApi.login);
const $isPending = loginToAccountFx.pending;

sample({
  clock: loginToAccount,
  target: loginToAccountFx,
});

sample({
  clock: loginToAccountFx.doneData,
  fn: (data) => data.data.token,
  target: authModel.token.events.setToken,
});

sample({
  clock: loginToAccountFx.doneData,
  fn: (data) => ({
    name: data.data.name,
  }),
  target: profileModel.profile.events.setData,
});

sample({
  clock: loginToAccountFx.doneData,
  fn: () => ({ pathname: endPoints.SHOPS, search: '' }),
  target: replace,
});

export const login = {
  events: {
    loginToAccount,
  },
  effects: {
    loginToAccountFx,
  },
  pendings: {
    $isPending,
  },
};
