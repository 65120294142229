import React, { FC } from 'react';
import { sharedCss } from 'shared/css';
import { Text } from 'shared/ui/atoms/Text';
import { BoxHeader } from 'shared/ui/molecules/BoxHeader';
import { notificationsCss } from './style';
import { Form } from './ui/Form';

export const Notifications: FC = () => {
  return (
    <div className={sharedCss.pageContainer}>
      <div className={sharedCss.boxContainer}>
        <BoxHeader title="Уведомления в Telegram" />
        <div className={notificationsCss.content}>
          <Text size="h4" className={notificationsCss.text}>
            1. Создайте публичный канал в Telegram. И получите{' '}
            <b>@channelusername</b>
          </Text>
          <Text size="h4" className={notificationsCss.text}>
            2. Добавьте Нашего бота <b>@notify_clickbot</b> в администраторы
            канала с правми на отправку сообщений
          </Text>
          <Text size="h4" className={notificationsCss.text}>
            3. Вставьте Имя пользователя канала в поле и сохраните
          </Text>
          <Form />
        </div>
      </div>
    </div>
  );
};
