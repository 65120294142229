import { createEffect, createEvent, createStore, sample } from 'effector';
import { ordersApi } from 'entities/Orders/api';
import { TUpdateStatusFx } from '../types';
import { servicesModel } from 'entities/Services/model';
import { order } from './order';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';
import { EOrderStatus } from 'shared/types/api/orders';
import { ordersModel } from 'entities/Orders/model';

const resetIsCanceling = createEvent();
const $isCanceling = createStore<boolean>(false).reset(resetIsCanceling);

const updateStatus = createEvent<string>();
const updateStatusFx = createEffect<TUpdateStatusFx>(
  ordersApi.updateOrderStatus,
);

const $isPending = updateStatusFx.pending;

sample({
  clock: updateStatus,
  source: {
    service: servicesModel.selectedService.stores.$service,
    order: order.stores.$data,
  },
  fn: ({ service, order }, status) => ({
    shopId: service.id,
    id: order.id,
    params: {
      status,
    },
  }),
  target: updateStatusFx,
});

sample({
  clock: updateStatus,
  filter: (status) => Boolean(status === EOrderStatus.CANCELED),
  fn: () => true,
  target: $isCanceling,
});

sample({
  clock: updateStatusFx.doneData,
  fn: (res) => res.data,
  target: [order.events.setOrder, ordersModel.list.events.updateOrder],
});

sample({
  clock: updateStatusFx.doneData,
  fn: (res): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: `orderDetails.statusChangedTo.${res.data.status}`,
    },
  }),
  target: showToastEvent,
});

sample({
  clock: [updateStatusFx.doneData, updateStatusFx.failData],
  target: resetIsCanceling,
});

export const actions = {
  stores: {
    $isCanceling,
  },
  events: {
    updateStatus,
  },
  pendings: {
    $isPending,
  },
};
