import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components, GroupBase, OptionProps } from 'react-select';
import { Image } from '../Image';
import { defaultSelectStyle } from './config';
import { selectCss } from './style';
import { ISelect, ISelectOption } from './types';
import { sharedCss } from 'shared/css';
import { Text } from '../Text';

/*
 *  TODO: https://github.com/JedWatson/react-select/issues/810 посмотреть issue с
 *   отображением меню в элементах с оверфлоу
 * */

export const Select: FC<ISelect> = ({
  options,
  value,
  defaultValue,
  placeholder,
  styleType = 'default',
  errorKey,
  errorRowCount = 1,
  wrapperClass = '',
  selectClass = '',
  imageClass = '',
  height = '50',
  borderRadius = '14',
  isDisabled,
  isWithError = true,
  isDefault = true,
  isWithI18n = false,
  onChange,
  onInputChange,
  inputValue,
  isSearchable = true,
  isWithArrow = true,
  testId,
  blurInputOnSelect = true,
  closeMenuOnSelect = true,
  isMulti = false,
  hideSelectedOptions = false,
  menuPlacement = 'auto',
  labelKey,
  disableFilter = false,
  menuIsOpen,
  customFilterOption,
  name,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const onFocus = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setTimeout(() => {
      if (isOpen) setIsOpen(false);
    }, 200);
  };

  const Option = (
    props: React.PropsWithChildren<
      OptionProps<ISelectOption, false, GroupBase<ISelectOption>>
    >,
  ) => (
    <components.Option {...props}>
      {!props.data.optionElement ? (
        <>
          {props.data.src && (
            <Image
              src={props.data.src as string}
              alt={`option ${props.data.label}`}
              className={`${selectCss.imageDefault} ${imageClass}`}
            />
          )}
          {isWithI18n ? t(props.data.label) : props.data.label}
        </>
      ) : (
        <>{props.data.optionElement}</>
      )}
    </components.Option>
  );

  const SingleValue = (props) => (
    <components.SingleValue {...props}>
      {props.data.src && (
        <Image
          src={props.data.src}
          alt={`option ${props.data.label}`}
          className={`${selectCss.imageDefault} ${imageClass}`}
        />
      )}
      {isWithI18n ? t(props.data.label) : props.data.label}
    </components.SingleValue>
  );

  return (
    <div
      className={`${selectCss.defaultWrapper} ${wrapperClass}`}
      data-border-radius={borderRadius}
      data-height={height}
      data-cy={testId}
      data-is-with-arrow={isWithArrow}
      data-is-error={Boolean(errorKey)}>
      {labelKey && (
        <Text
          size="text"
          color="secondary"
          weight="bold"
          className={selectCss.label}>
          {t(labelKey)}
        </Text>
      )}
      {isDefault ? (
        <ReactSelect
          filterOption={
            disableFilter
              ? null
              : customFilterOption ??
                ((option, input) =>
                  option.label.toLowerCase().includes(input.toLowerCase()))
          }
          isSearchable={isSearchable}
          onInputChange={onInputChange}
          inputValue={inputValue}
          isDisabled={isDisabled}
          menuPlacement={menuPlacement}
          styles={defaultSelectStyle}
          options={options}
          isMulti={isMulti}
          menuIsOpen={menuIsOpen}
          onChange={onChange}
          name={name}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          className={`${
            styleType === 'default' ? selectCss.defaultSelect : ''
          } ${selectClass}`}
          blurInputOnSelect={blurInputOnSelect}
          closeMenuOnSelect={closeMenuOnSelect}
          components={{
            Option,
            SingleValue,
          }}
          classNamePrefix="react-select"
          {...(isMulti && { hideSelectedOptions })}
        />
      ) : (
        <ReactSelect
          name={name}
          filterOption={
            disableFilter
              ? null
              : customFilterOption ??
                ((option, input) =>
                  option.label.toLowerCase().includes(input.toLowerCase()))
          }
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          menuPlacement={menuPlacement}
          styles={defaultSelectStyle}
          hideSelectedOptions={hideSelectedOptions}
          data-height={height}
          options={options}
          isMulti={isMulti}
          onChange={onChange}
          value={value}
          menuIsOpen={isOpen}
          onFocus={onFocus}
          onMenuClose={onClose}
          defaultValue={defaultValue}
          placeholder={placeholder}
          blurInputOnSelect={blurInputOnSelect}
          className={`${
            styleType === 'default' ? selectCss.defaultSelect : ''
          } ${selectClass}`}
          components={{
            Option,
            SingleValue,
          }}
          classNamePrefix="react-select"
        />
      )}
      {isWithError && (
        <p
          className={sharedCss.errorClass}
          data-error-row-count={errorRowCount}>
          {errorKey && t(errorKey)}
        </p>
      )}
    </div>
  );
};
