import { Store } from 'effector';
import { Rule } from 'effector-forms';
import i18next from 'i18next';
import { EMAIL, ONLY_NUMBERS, PHONE } from 'shared/config/regex';

export const rules = {
  isJSON: (): Rule<string> => {
    return {
      name: 'isJSON',
      validator: (value) => {
        try {
          JSON.parse(value);
          return {
            isValid: true,
          };
        } catch (err) {
          return {
            isValid: false,
            errorText: 'Value must be as JSON',
          };
        }
      },
    };
  },
  required: function <T = any>(text?: string): Rule<T> {
    return {
      name: 'required',
      validator: (value) => ({
        isValid: Boolean(value),
        errorText: text || 'errors.isRequired',
      }),
    };
  },
  requiredByCondition: <T = any>(
    isNeedCheck: Store<boolean>,
    text?: string,
  ): Rule<T> => ({
    name: 'requiredByCondition',
    source: isNeedCheck,
    validator: (value, _, isNeedCheck) => ({
      isValid: isNeedCheck ? Boolean(value) : true,
      errorText: text || 'errors.isRequired',
    }),
  }),
  minArrayLength: (minLength: number, text?: string): Rule<[]> => {
    return {
      name: 'minArrayLength',
      validator: (value) => ({
        isValid: Boolean(value?.length >= minLength),
        error: text ?? i18next.t('errors.minArrayLength', { minLength }),
      }),
    };
  },
  onlyNumbers: () => ({
    name: 'onlyNumbers',
    validator: (value: string) => {
      return {
        isValid: new RegExp(ONLY_NUMBERS).test(value) || value === '',
        errorText: 'Allowed symbols [0-9 ]',
      };
    },
  }),
  isEmailValid: (): Rule<string> => ({
    name: 'isEmailValid',
    validator: (value) => ({
      isValid: EMAIL.test(value),
      errorText: 'errors.invalidEmail',
    }),
  }),
  isPhoneValid: (): Rule<string> => ({
    name: 'isPhoneValid',
    validator: (value) => ({
      isValid: PHONE.test(value),
      errorText: 'errors.invalidPhone',
    }),
  }),
  authPasswordLength: (): Rule<string> => ({
    name: 'authPasswordLength',
    validator: (value) => ({
      isValid: value.length >= 4 && value.length <= 16,
      errorText: 'errors.invalidPasswordLength',
    }),
  }),
};
