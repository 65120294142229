import React from 'react';
import { toast } from 'react-toastify';
import { Toast } from 'shared/ui/molecules/Toast';
import { toastClass } from 'shared/theme/GlobalStyles';
import { TShowToast } from 'shared/types/toast';

export const showToast: TShowToast = ({
  toastParams,
  autoClose = 5000,
  position = 'bottom-right',
  className = toastClass,
}) => {
  return toast(<Toast {...toastParams} />, {
    autoClose,
    position,
    hideProgressBar: true,
    className: className,
    closeOnClick: true
  });
};
