import { createEffect, createEvent, sample } from 'effector';
import { productsApi } from 'entities/Products/api';
import { TDeleteProductFx } from '../types';
import { servicesModel } from 'entities/Services/model';
import { productsModel } from 'entities/Products/model';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';

const deleteProduct = createEvent<string>();
const deleteProductFx = createEffect<TDeleteProductFx>(
  productsApi.deleteProduct,
);

sample({
  clock: deleteProduct,
  source: servicesModel.selectedService.stores.$service,
  fn: (service, productId) => ({ shopId: service.id, productId }),
  target: deleteProductFx,
});

sample({
  clock: deleteProductFx.done,
  fn: ({ params }) => params.productId,
  target: productsModel.products.events.removeProduct,
});

sample({
  clock: deleteProductFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'Товар удален!',
    },
  }),
  target: showToastEvent,
});

export const productActions = {
  events: {
    deleteProduct,
  },
};
