import React, { FC } from 'react';
import { textCss } from './style';
import { ITextProps } from './types';

export const Text: FC<ITextProps> = ({
  children,
  color = 'default',
  size = 'text',
  weight = '500',
  className,
}) => {
  return (
    <p
      className={`${textCss.text} ${className}`}
      data-is-color={color}
      data-is-size={size}
      style={{ fontWeight: weight }}>
      {children}
    </p>
  );
};
