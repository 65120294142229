import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Svg } from 'shared/ui/atoms/Svg';
import { Text } from 'shared/ui/atoms/Text';
import { IToast } from 'shared/types/toast';
import { message, svg, toast } from './style';

export const Toast: FC<IToast> = ({
  iconId,
  type,
  messageText,
  messageKey,
  translateParams,
}) => {
  const { t } = useTranslation();
  const idIcon = type === 'custom' ? iconId : type;

  return (
    <div className={toast}>
      <Svg idIcon={idIcon} className={svg} spriteName="toast" />
      <Text className={message} size="h4">
        {messageText ?? t(messageKey, translateParams)}
      </Text>
    </div>
  );
};
