import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const pageContainer = css`
  flex: 1;
`;

const boxContainer = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${theming((theme) => ({
    background: theme.object.primaryObject,
    boxShadow: `0 5px 15px ${theme.commonBoxShadow}`,
  }))}
`;

const row = css`
  display: flex;
  align-items: center;
`;

const errorClass = css`
  padding: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  height: 20px;

  &[data-error-row-count='2'] {
    height: 40px;
  }

  ${theming((theme) => ({ color: theme.text.warningText }))}
`;

export const sharedCss = {
  pageContainer,
  boxContainer,
  row,
  errorClass,
  animation: {
    fadeIn: css`
      animation: fadeIn 0.15s ease-out;
      animation-fill-mode: forwards;
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `,
    fadeOut: css`
      animation: fadeOut 0.15s ease-out;
      animation-fill-mode: forwards;
      @keyframes fadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    `,
  },
};
