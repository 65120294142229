import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  FullscreenControl,
  TypeSelector,
  YMaps,
  Map as YMap,
  ZoomControl,
  Placemark,
} from '@pbe/react-yandex-maps';
import { IMapProps } from './types';
import { useUnit } from 'effector-react';
import { servicesModel } from 'entities/Services/model';
import { mapCss } from './style';
import { Button } from 'shared/ui/atoms/Button';
import { Text } from 'shared/ui/atoms/Text';

const DEFAULT_STATE = { center: [41.311158, 69.279737], zoom: 14 };

export const Map: FC<IMapProps> = ({ width }) => {
  const [service, isPending] = useUnit([
    servicesModel.selectedService.stores.$service,
    servicesModel.address.pendings.$isUpdatePending,
  ]);
  const [mapRef, setMapRef] = useState<ymaps.Map>(null);
  const [address, setAddress] = useState<string>(null);
  const [coords, setCoords] = useState<string[]>(null);

  const isNewAddress =
    (coords && coords !== service?.coords) ||
    (address && address !== service?.address);

  useEffect(() => {
    if (service?.coords) {
      setCoords(service.coords);
    }
    if (service?.address) {
      setAddress(service.address);
    }
  }, [service]);

  useEffect(() => {
    if (coords?.length && mapRef) {
      mapRef.panTo([Number(coords[0]), Number(coords[1])]);
    }
  }, [coords, mapRef]);

  const onMapInit = (ref: ymaps.Map) => {
    if (ref) {
      setMapRef(ref);

      ref.events.add('click', async (e) => {
        const [lat, lng] = e.get('coords');
        setCoords([lat.toString(), lng.toString()]);
        const geoResult = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=AIzaSyA_0tgumuCJdcNqbhw-w_T8pRVQ9CkFNTg`,
          { method: 'GET' },
        ).then(async (res) => res.json());
        const address = geoResult?.results?.[0]?.formatted_address;
        setAddress(address || '');
      });
    }
  };

  const onClickSave = async () => {
    servicesModel.address.events.updateAddress({
      address,
      latitude: coords[0],
      longitude: coords[1],
    });
  };

  return (
    <Fragment>
      <div className={mapCss.container}>
        <YMaps
          query={{
            lang: 'ru_RU',
            apikey: process.env.REACT_APP_YANDEX_API_KEY,
            suggest_apikey: process.env.REACT_APP_YANDEX_SUGGESTIONS_API_KEY,
          }}>
          <YMap
            instanceRef={onMapInit}
            width={width}
            height={400}
            defaultState={DEFAULT_STATE}
            options={{ yandexMapDisablePoiInteractivity: true }}>
            <FullscreenControl />
            <TypeSelector />
            <ZoomControl />
            {coords && <Placemark geometry={coords} />}
          </YMap>
        </YMaps>
        {isNewAddress && (
          <Button
            isLoading={isPending}
            className={mapCss.saveBtn}
            onClick={onClickSave}>
            Сохранить адрес
          </Button>
        )}
      </div>
      <div className={mapCss.addressBox} data-is-new={isNewAddress}>
        <Text color="secondary" size="h4">
          Адрес: <b>{address || 'Не установлен'}</b>
        </Text>
        <Text color="secondary" size="h4">
          Координаты: <b>{coords?.toString() || 'Не установлены'}</b>
        </Text>
      </div>
    </Fragment>
  );
};
