import {
  CREATE_SERVICE,
  GET_SHOPS,
  UPDATE_SERVICE_ADDRESS,
  UPDATE_SERVICE_CHANNEL,
} from 'shared/api/endpoints';
import { API } from 'shared/api/executor';
import {
  TCreateService,
  TGetServices,
  TUpdateServiceAddress,
  TUpdateServiceChannel,
} from '../types';

const getServices: TGetServices = () => API().get(GET_SHOPS);

const createService: TCreateService = (params) =>
  API().post(CREATE_SERVICE, params);

const updateServiceAddress: TUpdateServiceAddress = ({ shopId, params }) =>
  API().post(UPDATE_SERVICE_ADDRESS(shopId), params);

const updateServiceChannel: TUpdateServiceChannel = ({ shopId, params }) =>
  API().post(UPDATE_SERVICE_CHANNEL(shopId), params);

export const servicesApi = {
  getServices,
  createService,
  updateServiceAddress,
  updateServiceChannel,
};
