import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const button = css`
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  border: 1px solid transparent;
  margin-top: 15px;

  &:first-child {
    margin-top: 0;
  }

  ${theming((theme) => ({ backgroundColor: theme.object.primaryObject }))}

  &:hover {
    ${theming((theme) => ({ borderColor: theme.colors.blue }))}
  }

  &[data-is-selected='true'] {
    ${theming((theme) => ({
      backgroundColor: theme.button.filled.primary.background.default,
    }))}

    p {
      ${theming((theme) => ({ borderColor: theme.colors.white }))}
    }
  }
`;

export const shopButtonCss = {
  button,
};
