import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 25px;

  ${theming((theme) => ({
    background: theme.header.background,
    boxShadow: `0 0 15px ${theme.commonBoxShadow}`,
  }))}
`;

export const headerCss = {
  container,
};
