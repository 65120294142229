import React, { FC } from 'react';
import { profileCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { useUnit } from 'effector-react';
import { Svg } from 'shared/ui/atoms/Svg';
import { useDropdown } from 'shared/lib/useDropdown';
import { Dropdown } from 'shared/ui/atoms/Dropdown';
import { DropdownItem } from '../DropdownItem';
import { authModel } from 'entities/Auth/model';
import { profileModel } from 'entities/Profile/model';
import { servicesModel } from 'entities/Services/model';
import { confirmModalModel } from 'features/ConfirmModal/model';
import { productsModel } from 'entities/Products/model';
import { ordersModel } from 'entities/Orders/model';
import { statisticsModel } from 'entities/Statistics/model';

export const Profile: FC = () => {
  const [profile, shop] = useUnit([
    profileModel.profile.stores.$data,
    servicesModel.selectedService.stores.$service,
  ]);
  const { ref, dropdown, setDropdown } = useDropdown({
    isClickOutside: true,
    initialState: false,
  });

  const onToggle = () => {
    setDropdown(!dropdown);
  };

  const onClickLogout = () => {
    confirmModalModel.modal.setVisible({
      isOpen: true,
      params: {
        title: 'profile.logoutConfirmTitle',
        onConfirm: () => {
          setDropdown(false);
          authModel.logout.events.logoutAccount();
          confirmModalModel.modal.resetModal();
        },
      },
    });
  };

  const onClickChangeCompany = () => {
    setDropdown(false);
    servicesModel.selectedService.events.resetService();
    productsModel.products.events.resetAll();
    ordersModel.cart.events.reset();
    ordersModel.list.events.reset();
    statisticsModel.stats.events.reset();
  };

  return (
    <div ref={ref} className={profileCss.container}>
      <button className={profileCss.button} onClick={onToggle}>
        <div>
          <Text weight="bold">{profile?.name}</Text>
          <Text color="secondary">{shop?.name}</Text>
        </div>
        <div>
          <Svg idIcon="icArrowDown" width={20} height={20} />
        </div>
      </button>
      {dropdown && (
        <Dropdown isOpen={dropdown} className={profileCss.dropdown}>
          <DropdownItem
            icon={<Svg idIcon="icRefresh" width={20} height={20} />}
            title="Сменить приложение"
            onClick={onClickChangeCompany}
          />
          <DropdownItem
            icon={<Svg idIcon="icLogout" width={20} height={20} />}
            title="Выйти"
            onClick={onClickLogout}
          />
        </Dropdown>
      )}
    </div>
  );
};
