import { css } from '@linaria/core';

const container = css`
  margin-bottom: 15px;
`;

const uploadedFile = css`
  margin-top: 15px;
`;

export const fileUploadCss = {
  container,
  uploadedFile
};
