import { css } from '@linaria/core';

const grid = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
`;

export const homeCss = {
  grid,
};
