import React, { FC, Fragment } from 'react';
import { Text } from 'shared/ui/atoms/Text';

import { CreateButton } from '../CreateButton';

export const CreateCompanyTitle: FC = () => {
  return (
    <Fragment>
      <Text size="h1" weight="bold">
        У вас пока нету приложений
      </Text>
      <Text size="h4" weight="400">
        Создайте приложение для того чтобы продолжить пользоваться системой
      </Text>
      <CreateButton />
    </Fragment>
  );
};
