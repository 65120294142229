import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const wrapper = css`
  border-radius: 10px;
  border: 2px dashed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;

  ${theming((theme) => ({
    borderColor: theme.stroke.primary,
  }))}
`;

const icFile = css`
  width: 32px;
  height: 40px;
  transition: color 0.2s ease-in-out;
  ${theming((theme) => ({ color: theme.icon.accent }))}
`;

const dragAndDropLabel = css`
  margin-top: 10px;
  font-size: 16px;
  line-height: 26px;
  transition: color 0.2s ease-in-out;
`;

const dragAndDropArea = css`
  border: 1px solid;
  height: 168px;
  padding: 10px;
  width: 100%;
  min-width: 150px;
  border-radius: 14px;

  ${theming((theme) => ({
    background: setAlpha(theme.colors.blue, 0.025),
    borderColor: theme.stroke.primary,
  }))}

  &:hover {
    ${theming((theme) => ({
      background: setAlpha(theme.colors.blue, 0.05),
    }))}
  }

  &[data-is-drag-active='true'] {
    .${wrapper} {
      ${theming((theme) => ({
        borderColor: theme.stroke.accent,
      }))}
    }

    .${dragAndDropLabel}, .${icFile} {
      ${theming((theme) => ({ color: theme.text.primaryAccent }))}
    }
  }

  &[data-has-error='true'] {
    ${theming((theme) => ({
      background: setAlpha(theme.colors.red, 0.025),
    }))}

    .${wrapper} {
      ${theming((theme) => ({
        borderColor: theme.colors.red,
      }))}
    }

    .${dragAndDropLabel}, .${icFile} {
      ${theming((theme) => ({ color: theme.colors.red }))}
    }
  }
`;

export const dragZoneCss = {
  wrapper,
  icFile,
  dragAndDropLabel,
  dragAndDropArea,
};
