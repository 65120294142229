import { theming } from 'shared/theme';
import { css } from '@linaria/core';

const label = css`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;

  ${theming((theme) => ({ color: theme.text.secondaryText }))}
`;

const inputWrapper = css`
  width: 100%;
  height: 50px;
  position: relative;
  border: 1px solid;
  border-radius: 14px;
  transition: all 0.25s ease-in-out;
  display: flex;
  align-items: center;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 200000s ease-in-out 200000s, color 0s 200000s;
  }

  ${theming((theme) => ({
    caretColor: theme.text.primaryText,
    color: theme.text.primaryText,
    borderColor: theme.stroke.primary,
  }))}

  &[data-is-focused='true'] {
    ${theming((theme) => ({
      borderColor: theme.stroke.accent,
    }))}
  }

  &[data-is-disabled='true'] {
    opacity: 0.7;
  }

  &[data-is-error='true'] {
    ${theming((theme) => ({
      borderColor: theme.stroke.error,
      background: theme.object.primaryErrorTextfield,
    }))}
  }
`;

const input = css`
  -webkit-appearance: none;
  font-size: 16px;
  font-weight: 400;
  padding: 0 15px;
  height: 100%;
  flex: 1;

  ${theming((theme) => ({
    caretColor: theme.text.primaryText,
    color: theme.text.primaryText,
  }))}

  &::placeholder {
    ${theming((theme) => ({
      color: theme.text.secondaryText,
    }))}
  }
`;

const leftContentWrapper = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

const rightContentWrapper = css`
  position: absolute;
  right: 5px;
  top: 0;
  margin: auto;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const clearButton = css`
  margin-left: 5px;
  & svg {
    ${theming((theme) => ({
      color: theme.icon.secondary,
    }))}
  }

  &:hover svg {
    ${theming((theme) => ({
      color: theme.icon.primary,
    }))}
  }
`;

export const inputCss = {
  label,
  input,
  rightContentWrapper,
  leftContentWrapper,
  inputWrapper,
  clearButton,
};
