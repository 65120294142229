import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IDragZone } from './types';
import { Svg } from 'shared/ui/atoms/Svg';
import { Text } from 'shared/ui/atoms/Text';
import { dragZoneCss } from './style';

export const DragZone: FC<IDragZone> = ({
  isDragActive,
  hasError = false,
  className = '',
  testId,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${dragZoneCss.dragAndDropArea} ${className}`}
      data-is-drag-active={isDragActive}
      data-has-error={hasError}
      data-cy={testId}>
      <div className={dragZoneCss.wrapper}>
        <Svg className={dragZoneCss.icFile} idIcon="icCopy" />
        <Text className={dragZoneCss.dragAndDropLabel} color="accent">
          {t('dropFile')}
        </Text>
      </div>
    </div>
  );
};
