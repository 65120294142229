import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const leftContent = css`
  flex: 1;
  padding-right: 15px;
`;

const title = css`
  margin-bottom: 15px;
`;

const icon = css`
  transition: 200ms;
`;

const linkBox = css`
  display: none;
  position: absolute;
  bottom: -80px;
  right: -80px;
  z-index: 1;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  animation: slideIn 0.2s ease-out;
  animation-fill-mode: forwards;

  ${theming((theme) => ({ backgroundColor: theme.colors.blue }))}

  &:hover {
    transform: scale(1.1);
  }

  & svg {
    transform: rotate(-145deg);
    margin-left: -15px;
    margin-top: -15px;

    ${theming((theme) => ({ color: theme.colors.white }))}
  }

  @keyframes slideIn {
    0% {
      bottom: -80px;
      right: -80px;
    }
    100% {
      bottom: -30px;
      right: -30px;
    }
  }
`;

const container = css`
  padding: 15px;
  display: flex;
  align-items: flex-end;
  border: 1px solid transparent;
  transition: 200ms;
  position: relative;
  overflow: hidden;

  ${theming((theme) => ({
    backgroundColor: theme.object.primaryObject,
    boxShadow: `0 5px 15px ${theme.commonBoxShadow}`,
  }))}

  &[data-is-clickable='true'] {
    cursor: pointer;

    &:hover {
      ${theming((theme) => ({ borderColor: theme.stroke.accent }))}

      .${icon} {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 1;
        width: 24px;
        height: 24px;
      }

      .${linkBox} {
        display: flex;
      }
    }
  }
`;

export const cardCss = {
  container,
  leftContent,
  title,
  icon,
  linkBox,
};
