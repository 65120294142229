import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { MainRoutes } from './Router/MainRoutes';
import { MainModals } from './Router/MainModals';
import { Login } from 'pages/Login';
import { useUnit } from 'effector-react';
import { authModel } from 'entities/Auth/model';
import { servicesModel } from 'entities/Services/model';
import { Shops } from 'pages/Shops';
import { useRoute } from 'shared/lib/useRoute';

export const Routes: FC = () => {
  useRoute();
  const [token, service] = useUnit([
    authModel.token.stores.$token,
    servicesModel.selectedService.stores.$service,
  ]);

  const renderRoutes = () => {
    if (token) {
      if (!service)
        return (
          <Switch>
            <Route exact path={endPoints.SHOPS}>
              <Shops />
            </Route>
            <Redirect to={endPoints.SHOPS} />
          </Switch>
        );

      return (
        <Switch>
          <Route
            exact
            path={[
              endPoints.HOME,
              endPoints.PRODUCTS,
              endPoints.PRODUCT_CREATE,
              endPoints.PRODUCT_EDIT,
              endPoints.PRODUCT_VIEW,
              endPoints.CSV,
              endPoints.SETTINGS,
              endPoints.TEST,
              endPoints.ORDER,
              endPoints.ORDERS,
              endPoints.CART,
              endPoints.CART_ORDER,
              endPoints.NOTIFICATIONS,
            ]}>
            <MainRoutes />
          </Route>
          <Redirect to={endPoints.HOME} />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route exact path={endPoints.LOGIN}>
          <Login />
        </Route>
        <Redirect to={endPoints.LOGIN} />
      </Switch>
    );
  };

  return (
    <>
      {renderRoutes()}
      <MainModals />
    </>
  );
};
