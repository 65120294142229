import React, { FC } from 'react';
import { welcomeCss } from './style';
import { Text } from 'shared/ui/atoms/Text';

export const Welcome: FC = () => {
  return (
    <div className={welcomeCss.container}>
      <Text size="h1" weight="bold">
        Добро пожаловать в <span className={welcomeCss.name}>ClickBots</span>
      </Text>
      <Text size="h4" weight="400" className={welcomeCss.subtitle}>
        Пожалуйста введите Ваш <b>Merchant ID</b>
      </Text>
    </div>
  );
};
