import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  text-align: center;
  margin-bottom: 30px;

  & p:first-child {
    margin-bottom: 5px;
  }
`;

const name = css`
  padding: 0 15px;
  border-radius: 8px;

  ${theming((theme) => ({
    color: theme.text.primaryAccent,
    backgroundColor: theme.object.primaryObject,
  }))}
`;

const subtitle = css`
  margin-top: 15px;
`;

export const welcomeCss = { container, name, subtitle };
