import { createForm } from 'effector-forms';
import { ILoginFormFields } from '../types';
import { sample } from 'effector';
import { login } from './login';
import { rules } from 'shared/lib/rules';
import { ILoginParams } from 'shared/types/api/auth';

const $form = createForm<ILoginFormFields>({
  fields: {
    merchant_id: {
      init: '',
      rules: [rules.required()],
    },
  },
  validateOn: ['change', 'submit'],
});

sample({
  clock: $form.formValidated,
  fn: (fields): ILoginParams => ({
    name: `Merchant ${fields.merchant_id}`,
    merchant_id: fields.merchant_id,
    merchant_user_id: fields.merchant_id,
  }),
  target: login.events.loginToAccount,
});

sample({
  clock: login.effects.loginToAccountFx.doneData,
  target: $form.reset,
});

export const form = {
  stores: {
    $form,
  },
};
