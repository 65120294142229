import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  UPDATE_PRODUCT,
} from 'shared/api/endpoints';
import { API, FORM_DATA } from 'shared/api/executor';
import {
  TCreateProduct,
  TDeleteProduct,
  TGetProduct,
  TGetProducts,
  TUpdateProduct,
} from '../types';

const getProducts: TGetProducts = (shopId) => API().get(GET_PRODUCTS(shopId));

const getProduct: TGetProduct = (params) =>
  API().get(GET_PRODUCT(params.shopId, params.productId));

const createProduct: TCreateProduct = (shopId, params) =>
  API().post(CREATE_PRODUCT(shopId), params, {
    headers: { 'Content-Type': FORM_DATA },
  });

const updateProduct: TUpdateProduct = (shopId, productId, params) =>
  API().post(UPDATE_PRODUCT(shopId, productId), params, {
    headers: { 'Content-Type': FORM_DATA },
  });

const deleteProduct: TDeleteProduct = (params) =>
  API().post(DELETE_PRODUCT(params.shopId, params.productId));

export const productsApi = {
  getProducts,
  getProduct,
  createProduct,
  updateProduct,
  deleteProduct,
};
