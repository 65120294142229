import React, { FC } from 'react';
import { cardCss } from './style';
import { ICardProps } from './types';
import { Text } from 'shared/ui/atoms/Text';
import { useTranslation } from 'react-i18next';
import { Svg } from 'shared/ui/atoms/Svg';

export const Card: FC<ICardProps> = ({
  titleKey,
  value,
  idIcon = null,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cardCss.container}
      {...(onClick && { onClick })}
      data-is-clickable={Boolean(onClick)}>
      <div className={cardCss.leftContent}>
        <Text
          size="h3"
          weight="400"
          color="secondary"
          className={cardCss.title}>
          {t(titleKey)}
        </Text>
        <Text size="h2" weight="bold">
          {value}
        </Text>
      </div>
      {idIcon && (
        <Svg className={cardCss.icon} idIcon={idIcon} width={40} height={40} />
      )}
      <div className={cardCss.linkBox}>
        <Svg idIcon="icArrowLongRight" width={24} height={24} />
      </div>
    </div>
  );
};
