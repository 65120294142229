import { createEffect, createEvent, forward } from 'effector';
import { showToast } from 'shared/lib/showToast';
import { IShowToast } from 'shared/types/toast';

const showToastFx = createEffect((params: IShowToast) => {
  showToast(params);
});

const showToastEvent = createEvent<IShowToast>();

forward({
  from: showToastEvent,
  to: showToastFx,
});

export { showToastEvent };
