import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  min-height: 81px;
  padding: 15px 20px;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${theming((theme) => ({ borderBottomColor: theme.stroke.primary }))}
`;

const hideDivider = css`
  border-bottom: none;
`;

const titleBox = css`
  display: flex;
  align-items: center;
`;

export const boxHeaderCss = {
  container,
  titleBox,
  hideDivider,
};
