import { css } from '@linaria/core';

const input = css`
  padding-right: 140px;
`;

const uploadedImage = css`
  width: 250px;
  height: 250px;
  margin-top: 15px;
  border-radius: 14px;

  img {
    object-fit: cover;
  }
`;

const uploadButton = css`
  height: 40px;
`;

export const imageLoaderCss = {
  input,
  uploadedImage,
  uploadButton,
};
