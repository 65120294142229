import { css } from '@linaria/core';

const container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

const content = css`
  max-width: 470px;
  width: 100%;
`;

export const loginCss = { container, content };
