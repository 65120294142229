import React, { FC } from 'react';
import { sharedCss } from 'shared/css';
import { shopsCss } from './style';
import { ServiceList } from './ui/ServiceList';
import { Text } from 'shared/ui/atoms/Text';
import { Svg } from 'shared/ui/atoms/Svg';
import { authModel } from 'entities/Auth/model';
import { useUnit } from 'effector-react';
import { servicesModel } from 'entities/Services/model';
import { CreateCompanyTitle } from './ui/CreateCompanyTitle';

export const Shops: FC = () => {
  const servicesCount = useUnit(servicesModel.services.stores.$servicesCount);

  const onClickLogout = () => {
    authModel.logout.events.logoutAccount();
  };

  return (
    <div className={`${sharedCss.pageContainer} ${shopsCss.container}`}>
      <div className={shopsCss.content}>
        {servicesCount ? <ServiceList /> : <CreateCompanyTitle />}
        <button className={shopsCss.logout} onClick={onClickLogout}>
          <Svg idIcon="icLogout" width={30} height={30} />
          <Text size="h4">Выйти из аккаунта</Text>
        </button>
      </div>
    </div>
  );
};
