import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const forgotButton = css`
  display: block;
  margin-left: auto;

  & p {
    text-decoration: underline;
  }

  &:hover p {
    ${theming((theme) => ({ color: theme.text.primaryHoverAccent }))}
  }
`;

const submit = css`
  margin: 30px auto 0 auto;
  max-width 310px;
  width: 100%;
  justify-content: center;
`;

const input = css`
  height: 100%;
`;

const inputWrapper = css`
  ${theming((theme) => ({
    backgroundColor: theme.object.primaryTextfield
  }))}
`;

export const formCss = {
  forgotButton,
  submit,
  input,
  inputWrapper
};
