import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const container = css`
  padding: 30px 0;
  overflow-y: auto;
`;

const content = css`
  max-width: 470px;
  width: 100%;
  margin: 0 auto;
`;

const logout = css`
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 10px 15px;
  border-radius: 8px;
  ${theming((theme) => ({
    backgroundColor: setAlpha(theme.object.secondaryObject, 0.4),
  }))}

  &:hover {
    ${theming((theme) => ({ backgroundColor: theme.object.secondaryObject }))}
  }

  svg {
    margin-right: 10px;
  }
`;

export const shopsCss = {
  content,
  container,
  logout,
};
