import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const header = css`
  ${theming((theme) => ({ backgroundColor: theme.object.primaryObject }))}
`;

const container = css`
  &:not(:last-child) {
    margin-bottom: 30px;
    margin-right: 30px;
  }
`;

const row = css`
  display: flex;
  align-items: flex-start;
`;

const content = css`
  padding: 20px;
`;

const merhantHeader = css`
  ${theming((theme) => ({ backgroundColor: setAlpha(theme.colors.blue, 0.1) }))}
`;

const merhantHeaderTitle = css`
  ${theming((theme) => ({ color: `${theme.colors.blue} !important` }))}
`;

const serviceHeader = css`
  ${theming((theme) => ({
    backgroundColor: setAlpha(theme.colors.red, 0.1),
  }))}
`;

const serviceHeaderTitle = css`
  ${theming((theme) => ({
    color: `${theme.colors.red} !important`,
  }))}
`;

const rowKeyValue = css`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const urlBox = css`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  padding: 20px;
`;

const urlInputWrapper = css`
  width: 100%;
`;

const urlInput = css`
  padding-right: 50px;
`;

const copyUrlBtn = css`
  border-radius: 50px;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    ${theming((theme) => ({
      color: theme.icon.secondary,
    }))}
  }

  &:hover svg {
    ${theming((theme) => ({
      color: theme.icon.primary,
    }))}
  }
`;

export const testingCss = {
  header,
  container,
  row,
  content,
  merhantHeader,
  merhantHeaderTitle,
  serviceHeader,
  serviceHeaderTitle,
  rowKeyValue,
  urlBox,
  copyUrlBtn,
  urlInputWrapper,
  urlInput,
};
