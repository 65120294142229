import React, { FC } from 'react';
import { leftSidebarCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { menuList } from './config';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

export const LeftSidebar: FC = () => {
  const history = useHistory();
  const location = useLocation();

  const onClickItem = (route) => () => {
    route?.fn && route.fn();
    route?.endPoint && history.push(route.endPoint);
  };

  return (
    <div className={leftSidebarCss.container}>
      <div className={leftSidebarCss.menu}>
        {menuList.map((section) => (
          <div key={section.name} className={leftSidebarCss.section}>
            <Text
              color="accent"
              weight="bold"
              className={leftSidebarCss.sectionName}>
              {section.name}
            </Text>
            {section.routes.map((item) => (
              <button
                key={item.name}
                className={leftSidebarCss.menuItem}
                data-is-active={location.pathname.includes(item?.endPoint)}
                onClick={onClickItem(item)}>
                <Text color="secondary">{item.name}</Text>
              </button>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
