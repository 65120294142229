import { EOrderStatus } from 'shared/types/api/orders';

export const getActionName = (status) => {
  switch (status) {
    case EOrderStatus.PAID:
      return {
        nextStatus: EOrderStatus.READY,
        name: 'orderDetails.actions.readyForPickup',
      };
    case EOrderStatus.READY:
      return {
        nextStatus: EOrderStatus.DONE,
        name: 'orderDetails.actions.finish',
      };
    default:
      null;
  }
};
