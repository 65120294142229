import { API } from 'shared/api/executor';
import {
  TGetCartList,
  TGetOrder,
  TGetOrders,
  TUpdateOrderStatus,
} from '../types';
import {
  GET_CART_LIST,
  GET_ORDER,
  GET_ORDERS,
  UPDATE_ORDER_STATUS,
} from 'shared/api/endpoints';
import queryString from 'query-string';

const getOrders: TGetOrders = ({ shopId, filters }) =>
  API().get(
    GET_ORDERS(shopId) +
      queryString.stringify(
        { ...filters },
        { skipNull: true, skipEmptyString: true },
      ),
  );

const getCartList: TGetCartList = ({ shopId }) =>
  API().get(GET_CART_LIST(shopId));

const getOrder: TGetOrder = (params) =>
  API().get(GET_ORDER(params.shopId, params.id));

const updateOrderStatus: TUpdateOrderStatus = ({ shopId, id, params }) =>
  API().post(UPDATE_ORDER_STATUS(shopId, id), params);

export const ordersApi = {
  getOrders,
  getCartList,
  getOrder,
  updateOrderStatus,
};
