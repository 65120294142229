import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import RU from './locales/ru.json';
import { ILocales } from './config';
import { TStrOrInt } from 'shared/types/common';

i18next.use(initReactI18next).init({
  resources: {
    RU: {
      translation: RU,
    },
  },
  fallbackLng: ILocales.RU,
  interpolation: {
    escapeValue: false,
  },
});

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (TStrOrInt)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (TStrOrInt)];

export type TLocaleKey = NestedKeyOf<typeof RU> | '';
