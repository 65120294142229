import { useUnit } from 'effector-react';
import { useEffect, useRef, useState } from 'react';
import { IUseDropdown } from './types';
import { mobileModel } from 'shared/model/mobile';

export const useDropdown = ({
  initialState,
  isClickable = false,
  isClickOutside = true,
  onClickOutside,
}: IUseDropdown) => {
  const ref = useRef<HTMLDivElement>();
  const refDropdown = useRef<HTMLDivElement>();
  const isMobile = useUnit(mobileModel.stores.$isMobile);
  const [dropdown, setDropdown] = useState(initialState);
  const isTouchScreenDevice = isMobile;

  useEffect(() => {
    const handler = (event) => {
      if (
        dropdown &&
        ref.current &&
        !ref.current.contains(event.target) &&
        isClickOutside
      )
        setDropdown(false);
    };

    isTouchScreenDevice
      ? document.addEventListener('touchstart', handler)
      : document.addEventListener('mousedown', handler);

    if (!refDropdown.current || !dropdown) return;

    refDropdown.current.style.left = '0';
    refDropdown.current.style.right = 'auto';
    refDropdown.current.style.top = '100%';
    refDropdown.current.style.bottom = 'auto';

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const dropdownComponentRect = refDropdown.current.getBoundingClientRect();
    const isDropdownWidthMoreThanWindow =
      dropdownComponentRect.width > windowWidth;
    const dropdownWidth = isDropdownWidthMoreThanWindow
      ? windowWidth
      : dropdownComponentRect.width;
    const dropdownHorizontalPosition = dropdownWidth + dropdownComponentRect.x;
    const dropdownVerticalPosition =
      dropdownComponentRect.height + dropdownComponentRect.bottom;
    const isHorizontalPositionOutOfWidth =
      dropdownHorizontalPosition > windowWidth;
    const isVerticalPositionOutOfHeight =
      dropdownVerticalPosition > windowHeight;

    if (isDropdownWidthMoreThanWindow)
      refDropdown.current.style.width = `${windowWidth}px`;

    if (isHorizontalPositionOutOfWidth) {
      const left = dropdownHorizontalPosition - windowWidth;
      refDropdown.current.style.left = `-${left}px`;
    } else {
      refDropdown.current.style.left = '0';
      refDropdown.current.style.right = 'auto';
    }

    if (isVerticalPositionOutOfHeight) {
      refDropdown.current.style.top = 'auto';
      refDropdown.current.style.bottom = '100%';
    } else {
      refDropdown.current.style.top = '100%';
      refDropdown.current.style.bottom = 'auto';
    }

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onClick = (
    event?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
  ) => {
    if (isTouchScreenDevice || isClickable) {
      if (onClickOutside) onClickOutside();
      setDropdown((prev) => !prev);
      event?.preventDefault();
    }
  };

  const onMouseOver = () => {
    if (!isTouchScreenDevice) setDropdown((prev) => !prev);
  };

  const onMouseOut = () => {
    if (!isTouchScreenDevice) setDropdown((prev) => !prev);
  };

  return {
    ref,
    refDropdown,
    dropdown,
    onClick,
    onMouseOver,
    onMouseOut,
    setDropdown,
  };
};
