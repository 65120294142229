import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const copyButton = css`
  margin-left: auto;
  display: block;

  & svg {
    ${theming((theme) => ({
      color: theme.icon.secondary,
    }))}
  }

  &:hover svg {
    ${theming((theme) => ({
      color: theme.icon.primary,
    }))}
  }
`;

export const tableColumnCss = {
  copyButton,
};
