import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-top: 15px;

  button {
    margin-right: 15px;
    font-size: 16px;
    border-bottom: 2px solid transparent;
    height: 40px;
    width: 70px;

    ${theming((theme) => ({
      color: theme.text.primaryText,
    }))}

    &[data-is-active='true'] {
      ${theming((theme) => ({
        borderBottomColor: theme.colors.blue,
        color: theme.text.primaryAccent,
      }))}
    }
  }
`;

export const tabsCss = {
  container,
};
