import React, { FC, memo } from 'react';
import { headerCss } from './style';
import { ThemeButton } from './ui/ThemeButton';
import { Profile } from './ui/Profile';

export const Header: FC = memo(() => {
  return (
    <div className={headerCss.container}>
      <Profile />
      <ThemeButton />
    </div>
  );
});
