import { createEffect, createEvent, createStore, sample } from 'effector';
import { TOKEN } from '../config';
import { endPoints } from 'shared/config/endPoints';
import { replace } from 'shared/lib/redirect';

const resetToken = createEvent();
const setToken = createEvent<string>();
const $token = createStore<string>(localStorage.getItem(TOKEN) ?? null);

const cacheTokenFx = createEffect<string, void>((value) => {
  localStorage.setItem(TOKEN, value);
});

sample({
  clock: setToken,
  target: [cacheTokenFx, $token],
});

sample({
  clock: $token,
  filter: (token) => !token,
  fn: () => ({ pathname: endPoints.LOGIN, search: '' }),
  target: replace,
});

sample({
  clock: resetToken,
  fn: () => null,
  target: $token,
});

export const token = {
  stores: {
    $token,
  },
  events: {
    setToken,
    resetToken,
  },
};
